import { useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import GlobalStyle from "./theme/globalstyles";
import { QueryClient, QueryClientProvider } from "react-query";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import HomePage from "./Pages/HomePage";
import "antd/dist/antd.min.css";
// import PartnersWeb3Economy from "./components/PartnersWeb3Economy/PartnersWeb3Economy";

function App() {
  const queryClient = new QueryClient();
  const theme = useSelector((state) => state.theme.props);
  const { toasts } = useToasterStore();
  const TOAST_LIMIT = 3;
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation: ;
  }, [toasts]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Toaster position="top-right" />

          <HomePage />
          {/* <PartnersWeb3Economy /> */}
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
