import styled from "styled-components";

export const FotterBottomLinksContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 6rem;
  justify-content: flex-end;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
`;
export const FotterBottomLinksContainerLeft = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  text-transform: capitalize;
  color: #718fbb;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    order: 2;
  }
`;
export const FotterBottomLinksContainerRight = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    justify-content: center;
    gap: 1rem;
    order: 1;
  }
`;
export const Heading = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  /* V2/Light/400 */

  color: #8897c1;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
`;
export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  cursor: pointer;
`;
export const Link = styled.img`
  width: 80px;
  height: 20px;
`;
