import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;

  margin-top: 5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const LeftTextContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  /* font-size: 32px; */
  font-size: 1.7rem;
  line-height: 145%;
  letter-spacing: -1px;
  text-transform: capitalize;
  max-width: 29rem;
  letter-spacing: -1px;
  text-transform: capitalize;

  /* grad */

  background: linear-gradient(94.18deg, #ff0074 0%, #6038f7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: ${(props) => (props.theme.mode === 'dark' ? '#ffffff' : '#31374D')};
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    padding-bottom: 15px;
  }
`;

export const Wrapperdetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 70%;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    padding: 22px;
    box-sizing: border-box;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};

    border: 1px solid
      ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.black['200']
          : props.theme.colors.grey['300']};
    border-radius: 12px;
    max-width: 343px;
  }
`;

export const TotalProject = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#191C27' : ' #FFFFFF'};
  /* V2/Light/200 */

  border: 1px solid ${(props) => props.theme.mode === 'light' && '#E7EAF3'};
  box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;
export const Icon = styled.img`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 52px;
    height: 52px;
  }
`;
export const Text = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;

  margin-left: 10px;
  box-sizing: border-box;
  padding-left: 0px;
  position: relative;
`;
export const Number = styled.span`
  color: #fcb778;

  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-shadow: 0px 12px 64px rgba(252, 183, 120, 0.7);
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 22px;
  }
`;
export const Data = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #777d92;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 14px;
  }
`;

export const RaisedCapital = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#191C27' : ' #FFFFFF'};
  /* V2/Light/200 */

  border: 1px solid ${(props) => props.theme.mode === 'light' && '#E7EAF3'};
  box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 2rem;
`;

export const NumberRaisedCapital = styled.span`
  color: #33d89b;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-shadow: 0px 12px 64px rgba(51, 216, 155, 0.7);
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 22px;
  }
`;
export const DataRaisedCapital = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #777d92;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 14px;
  }
`;
export const ProjectRaise = styled.p`
  margin: 0;
  font-size: 2rem;
`;
