// import React from 'react';
// import Countdown from 'react-countdown';
// import ProgressBar from '../ProgressBar/ProgressBar';
// import CustomCountdown from '../CustomCountdown/CustomCountdown';
// import {
//   CardBanner,
//   CardBody,
//   CardInfo,
//   Logo,
//   ProjectName,
//   TokenName,
//   CardInfoWrapper,
//   CardInfoHeading,
//   CardInfoValue,
//   CardInfoHeadingSpan,
//   TypeOfProject,
//   SupportedToken,
//   SupportedTokenNamee,
//   SaleTimer,
// } from './HorizontalCard.style';
// import NetworkImg from '../NetworkImg/NetworkImg';
// import WhitelistTag from '../WhitelistTag/WhitelistTag';
// import ProjectTimer from '../ProjectTimer/ProjectTimer';

// // eslint-disable-next-line react/prop-types
// function HorizontalCard({ type, data }) {
//   console.log(data);
//   return (
//     <CardBody
//       to={
//         data.projectStatus === 'upcoming' && data.isUpcomingDisabled === false
//           ? `#`
//           : `/pools/${data._id}`
//       }
//     >
//       <CardBanner image={data.projectBannerUrl}>
//         <TypeOfProject>{data.typeOfProject}</TypeOfProject>
//         <CardInfo>
//           <Logo src={data.projectLogoUrl} />
//           <ProjectName>
//             {data.projectName}
//             <TokenName>{data.tokenDetails.tokenName}</TokenName>
//           </ProjectName>

//           <CardInfoWrapper>
//             <CardInfoHeading>Total Raised</CardInfoHeading>
//             <CardInfoValue>
//               {data.tokenDetails.totalFundraise === 0
//                 ? 'TBA'
//                 : `${data.tokenDetails.totalFundraise} $`}
//             </CardInfoValue>
//           </CardInfoWrapper>
//           <CardInfoWrapper>
//             <CardInfoHeading>Network Supported</CardInfoHeading>
//             <CardInfoValue>
//               <SupportedToken>
//                 <NetworkImg networkId={data.projectNetwork.networkId} />
//                 <SupportedTokenNamee>{data.supportedToken}</SupportedTokenNamee>
//               </SupportedToken>
//             </CardInfoValue>
//           </CardInfoWrapper>
//           {type === 'active' && (
//             <>
//               <CardInfoWrapper>
//                 <CardInfoHeading>
//                   Sales status
//                   <CardInfoHeadingSpan>(in progress)</CardInfoHeadingSpan>
//                 </CardInfoHeading>
//                 <CardInfoValue>
//                   <ProgressBar completed={50} width={110} />
//                 </CardInfoValue>
//               </CardInfoWrapper>

//               <CardInfoWrapper>
//                 <SaleTimer>Sales ends in</SaleTimer>
//                 <CardInfoValue>
//                   <ProjectTimer type={type} data={data} />
//                 </CardInfoValue>
//               </CardInfoWrapper>
//             </>
//           )}
//           {type === 'upcoming' && (
//             <>
//               <CardInfoWrapper>
//                 <CardInfoHeading>
//                   Sale status
//                   <CardInfoHeadingSpan>(in progress)</CardInfoHeadingSpan>
//                 </CardInfoHeading>
//                 <CardInfoValue>
//                   <WhitelistTag data={data} />
//                 </CardInfoValue>
//               </CardInfoWrapper>

//               <CardInfoWrapper>
//                 <SaleTimer>Sales starts in</SaleTimer>
//                 <CardInfoValue>
//                   <Countdown
//                     date={data.idoDates.roundTwoEndDate}
//                     renderer={CustomCountdown}
//                   />
//                 </CardInfoValue>
//               </CardInfoWrapper>
//             </>
//           )}
//         </CardInfo>
//       </CardBanner>
//     </CardBody>
//   );
// }

// export default HorizontalCard;

import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';
import {
  CardContainer,
  CardImage,
  CardTitle,
  Logo,
  ProgressContainer,
  ProjectName,
  RightContainer,
  SaleDescription,
  Text,
  // TimerContainer,
  TokenName,
  TotalRaise,
  VideoTag,
  NetworkInfo,
  PipeDivider,
  SupportedTokens,
  RowTextSpan,
  Divider,
} from './HorizontalCard.style';
import NetworkImg from '../../NetworkImg/NetworkImg';
import moment from 'moment';
// import ProgressBar from "../ProgressBar/ProgressBar";
// import WhitelistTag from "../WhitelistTag/WhitelistTag";
// // import ProjectTimer from '../ProjectTimer/ProjectTimer';
// import ProjectTimerHorizontal from "../ProjectTimer/ProjectTimerHorizontal";
// import { Web3Class } from "../../Web3/web3Class";

function HorizontalCard({ type, data }) {
  const theme = useTheme();
  // const themeMode = useSelector((state) => state.theme.value);
  // const singlePool = useSelector((state) => state.pools.selectedPoolV1);
  // const isSocketConnected = useSelector((state) => state.socket.isConnected);
  // const socket = useSelector((state) => state.socket.data);

  // const [percentage, setPercentage] = useState(0);

  // const getActiveCardPoolProgress = async () => {
  //   try {
  //     const web3Instance = new Web3Class(data);
  //     const percentageData =
  //       await web3Instance.calculateOverallPoolPercentage();
  //     // console.log(percentageData);
  //     setPercentage(percentageData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (type === "active") {
  //     getActiveCardPoolProgress();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isSocketConnected) {
  //     socket.on("project-progress", (projectData) => {
  //       if (projectData.projectId === singlePool._id) {
  //         setPercentage(projectData.overallPercentage);
  //       }
  //     });
  //   }
  // }, [isSocketConnected, socket, singlePool]);
  function convertDateFormat(d) {
    const dateToRender = moment.utc(d).format('Do MMM YYYY | HH:mm A');
    return `${dateToRender} UTC`;
  }
  console.log(data);
  return (
    <a href={`https://app.metalaunch.io/pools/${data[0]?._id}`} target='_blank'>
      <CardContainer>
        <CardImage src={data[0]?.projectBannerUrl} alt='project-banner' />
        <RightContainer>
          <CardTitle>
            <Logo src={data[0]?.projectLogoUrl} alt='project-logo' />
            <ProjectName>
              {data[0].projectName}
              <TokenName>{`$${data[0]?.tokenDetails?.tokenName}`}</TokenName>
            </ProjectName>
            <NetworkInfo>
              <NetworkImg networkId={data[0]?.projectNetwork?.networkId} />
              <PipeDivider>|</PipeDivider>
              <SupportedTokens>{data[0]?.supportedToken}</SupportedTokens>
            </NetworkInfo>
            <VideoTag type={data[0]?.typeOfProject}>
              {data[0]?.typeOfProject}
            </VideoTag>
          </CardTitle>
          <Text
            marginTop='1.5rem'
            color={
              theme.mode === 'dark'
                ? theme.colors.grey['400']
                : theme.colors.black['100']
            }
          >
            {data[0]?.projectDescription?.length > 280 ? (
              <>
                {data[0]?.projectDescription?.slice(0, 280)}
                <a href={`/pools/${data._id}`}>...Read More</a>
              </>
            ) : (
              data[0]?.projectDescription
            )}
          </Text>
          <SaleDescription>
            <TotalRaise>
              <Text
                color={
                  theme.mode === 'dark'
                    ? theme.colors.grey['300']
                    : theme.colors.black['200']
                }
              >
                Total Raise
              </Text>
              <Divider />
              <Text
                dollarValue
                color={
                  theme.mode === 'dark'
                    ? theme.colors.grey['300']
                    : theme.colors.black['200']
                }
              >
                {data[0]?.tokenDetails?.totalFundraise === 0
                  ? 'TBA'
                  : `${data[0]?.tokenDetails?.totalFundraise.toLocaleString()} USD`}
              </Text>
            </TotalRaise>

            <ProgressContainer>
              <Text
                marginBottom='8px'
                color={
                  theme.mode === 'dark'
                    ? theme.colors.grey['300']
                    : theme.colors.black['200']
                }
              >
                Sale Date
              </Text>
              <Divider />
              <RowTextSpan
                color={
                  theme.mode === 'dark'
                    ? theme.colors.grey['300']
                    : theme.colors.black['200']
                }
              >
                {convertDateFormat(data?.tokenDetails?.tokenSaleDate)}
              </RowTextSpan>

              {/* <ProgressBar completed={percentage} width={80} /> */}
            </ProgressContainer>
            {/* <ProjectTimerHorizontal data={data} /> */}

            {/* {type === "upcoming" && (
            <>
              <ProgressContainer>
                <Text
                  marginBottom="5px"
                  color={
                    theme.mode === "dark"
                      ? theme.colors.grey["300"]
                      : theme.colors.black["200"]
                  }
                >
                  Whitelist Status
                </Text>
                <WhitelistTag data={data} width={80} /> 
               </ProgressContainer>
              <ProjectTimerHorizontal data={data} />
            </>
          )} */}
          </SaleDescription>
        </RightContainer>
      </CardContainer>
    </a>
  );
}

// HorizontalCard.propTypes = {
//   type: PropTypes.string.isRequired,
//   data: PropTypes.objectOf().isRequired,
// };

export default HorizontalCard;
