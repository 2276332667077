import React, { useState } from "react";
import {
  Btn,
  CardBody,
  CardContainer,
  CardHead,
  CardText,
  DefiLogoImg,
  GameLogoImg,
  Heading,
  HeadSpan,
  LogoImg,
  NftLogoImg,
  ProductContainer,
  ProductWrapper,
  HeadSpan2,
} from "./Product.style";
import { useTheme } from "styled-components";
const Product = () => {
  const theme = useTheme();

  const [headColor, setHeadColor] = useState("white");
  const [headColor2, setHeadColor2] = useState("white");
  const [headColor3, setHeadColor3] = useState("white");
  const [headColor4, setHeadColor4] = useState("white");

  return (
    <ProductWrapper>
      <div>
        <Heading>
          <HeadSpan>Ecosystem </HeadSpan>
          <HeadSpan2>The Building Blocks Of Asvaverse.</HeadSpan2>
        </Heading>
      </div>

      <ProductContainer>
        <CardContainer
          backcolor="#5e50ff"
          onMouseEnter={() => setHeadColor2("black")}
          onMouseLeave={() => setHeadColor2("white")}
        >
          <LogoImg />
          <CardBody>
            <CardHead color={theme.mode === "dark" ? "white" : headColor2}>
              Launchpad
            </CardHead>

            <CardText>
              The MetaLaunch is a multi-chain metaverse and Web3 Launchpad, supporting promising builders and pioneering companies in raising capital while providing early token outlier deals to the community.
            </CardText>
          </CardBody>
          <Btn target="_blank" href="https://app.metalaunch.io/">
            Launch App
          </Btn>
        </CardContainer>
        <CardContainer
          backcolor="#FA539F"
          onMouseEnter={() => setHeadColor("black")}
          onMouseLeave={() => setHeadColor("white")}
        >
          <NftLogoImg />
          <CardBody>
            <CardHead color={theme.mode === "dark" ? "white" : headColor}>
              Metaverse Marketplace
            </CardHead>
            <CardText>
              The World’s first metaverse-centric marketplace to discover, trade
              and monetize virtual metaverse and in-game assets.
            </CardText>
          </CardBody>
          <Btn target="_blank" href="https://nft.asvalabs.com/">
            Launch App
          </Btn>
        </CardContainer>
        <CardContainer
          backcolor="#3BEEAD"
          onMouseEnter={() => setHeadColor4("black")}
          onMouseLeave={() => setHeadColor4("white")}
        >
          <GameLogoImg />
          <CardBody>
            <CardHead color={theme.mode === "dark" ? "white" : headColor4}>
              Asva Games Guild
            </CardHead>
            <CardText>
              Community-driven games guild connecting gamers with top-tier web3
              games, helping them to play, learn and earn, with top-notch
              scholarships, demystifying Web3 economy.
            </CardText>
          </CardBody>
          <Btn target="_blank" href="https://www.asvagamesguild.com/">
            Launch App
          </Btn>
        </CardContainer>
        <CardContainer
          backcolor="#FCB778"
          onMouseEnter={() => setHeadColor3("black")}
          onMouseLeave={() => setHeadColor3("white")}
        >
          <DefiLogoImg />
          <CardBody>
            <CardHead color={theme.mode === "dark" ? "white" : headColor3}>
              MetaFi DeFi Vault
            </CardHead>
            <CardText>
              Our Metafied DeFi platform is built to bring DeFi functionalities
              to the metaverse. It facilitates staking and yield farming on
              listed project tokens and offers competitive APR.
            </CardText>
          </CardBody>
          <Btn target="_blank" href="#">
            Coming Soon
          </Btn>
        </CardContainer>
      </ProductContainer>
    </ProductWrapper>
  );
};

export default Product;
