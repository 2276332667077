import React, { useState } from "react";
import {
  DesktopImage,
  FooterArrowImage,
  FooterDiv,
  FooterDivInput,
  FooterInput,
  FooterInputBtn,
  FooterInputBtnspan,
  FooterInputDivColumn,
  FooterP1,
  FooterP2,
  FooterP3,
  Message,
  MobileImage,
} from "./FooterMiddle.styles";
import Arrow from "./assets/Arrow.svg";
import SubscribeImage from "./assets/SubscribeImage.svg";
import axios from "axios";
// import { useTheme } from 'styled-components';

function FooterMiddle() {
  // const theme = useTheme();
  const [inputVal, setInputVal] = useState("");
  const [messageData, setMessageData] = useState();
  const handleSubscribe = () => {
    const email = inputVal;
    setInputVal("");
    //  var message = elem.querySelector("p");
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regex.test(String(email).toLowerCase())) {
      setMessageData("Please enter a valid email");
    }
    const formData = new FormData();
    formData.append("api_key", "AghazFUFk4uDrDUsquEg");
    formData.append("email", email);
    formData.append("list", "Ya5aiMLvPKaMZLzXN7vbmQ");
    axios
      .post("https://sendy.asvalabs.com/subscribe", formData, {
        headers: { "Content-type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        const resData = response.data;

        if (resData.includes(`You're already subscribed!`)) {
          setMessageData(`You're already subscribed!`);
        }
        if (resData.includes(`You're subscribed!`)) {
          setMessageData(`You're subscribed!`);
        }
      })
      .catch(() => {
        setMessageData(`Something went wrong!`);
      });
  };

  return (
    <FooterDiv>
      <MobileImage src={SubscribeImage} alt="" />
      <FooterInputDivColumn>
        {/* <FooterP3>Never miss a Launch!</FooterP3> */}
        {/* {width >= parseInt(theme.breakpoints.tablet, 10) ? (
          <FooterP1>
            Subscribe to stay in the loop with our newest Token <br />
            sale launches and ASVA ecosystem updates.
          </FooterP1>
        ) : ( */}

        <FooterP1 style={{ marginRight: "auto" }}>
          Never miss a Launch!
        </FooterP1>
        <FooterP1>
          Subscribe to stay in the loop with our newest Token sale launches and
          ASVA ecosystem updates.
        </FooterP1>

        {/* )} */}
        <FooterDivInput>
          <FooterInput
            type="text"
            placeholder="Enter your email address"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <FooterInputBtn>
            <FooterInputBtnspan onClick={() => handleSubscribe()}>
              Subscribe
              <FooterArrowImage src={Arrow} alt="Arrow" />
            </FooterInputBtnspan>
          </FooterInputBtn>
        </FooterDivInput>
        <Message>{messageData}</Message>
      </FooterInputDivColumn>
      <DesktopImage src={SubscribeImage} alt="" />
    </FooterDiv>
  );
}

export default FooterMiddle;
