import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  LeftContainer,
  Heading,
  Para,
  RightContainer,
  InputDiv,
  Input,
  Button,
  Icon,
  Message,
} from "./Subscribe.styles";
import Arrow from "./assets/Arrow.svg";

function SubscribeSection() {
  const [inputVal, setInputVal] = useState("");
  const [messageData, setMessageData] = useState();
  const handleSubscribe = () => {
    const email = inputVal;
    setInputVal("");
    //  var message = elem.querySelector("p");
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regex.test(String(email).toLowerCase())) {
      setMessageData("Please enter a valid email");
    }
    const formData = new FormData();
    formData.append("api_key", "AghazFUFk4uDrDUsquEg");
    formData.append("email", email);
    formData.append("list", "Ya5aiMLvPKaMZLzXN7vbmQ");
    axios
      .post("https://sendy.asvalabs.com/subscribe", formData, {
        headers: { "Content-type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        const resData = response.data;

        if (resData.includes(`You're already subscribed!`)) {
          setMessageData(`You're already subscribed!`);
        }
        if (resData.includes(`You're subscribed!`)) {
          setMessageData(`You're subscribed!`);
        }
      })
      .catch(() => {
        setMessageData(`Something went wrong!`);
      });
  };
  return (
    <Container>
      <LeftContainer>
        <Heading>Subscribe to receive updates regarding token sale</Heading>
        <Para>Unsubscribe at any time 😇</Para>
      </LeftContainer>
      <RightContainer>
        <InputDiv>
          <Input
            placeholder="Enter your email address"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <Button onClick={() => handleSubscribe()}>
            Subscribe <Icon src={Arrow} />
          </Button>
        </InputDiv>
        <Message>{messageData}</Message>
      </RightContainer>
    </Container>
  );
}

export default SubscribeSection;
