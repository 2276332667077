import React, { useEffect, useState } from "react";
import { useRef } from "react";
import moment from "moment";
import {
  Video,
  VideoTitleRow,
  ProjectName,
  DateTime,
  ProjectInfoContainer,
  AbsoluteControls,
  ControlsRow,
  VideoTag,
  SoundImg,
  ImageSlide,
  CustomCarouselHorizontal,
  CaraouselContainerHorizontal,
  AbsoluteVideoInfoHorizontal,
  Wrapper,
  Heading,
} from "./FeaturedProjects.style";
import volumeUp from "../../assets/volumeUp.svg";
import muteIcon from "../../assets/muteIcon.svg";
import { useTheme } from "styled-components";
import { getBackendUrl } from "../../api/constant";

function FeaturedProjects() {
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [isMute, setIsMute] = useState(true);
  const [index, setIndex] = useState(0);
  const carouselRef = useRef();
  const theme = useTheme();
  const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

  const goToNextSlide = () => {
    if (featuredProjects.length > 1) {
      carouselRef.current.next();
    }
  };

  function convertDateFormat(d) {
    const dateToRender = moment.utc(d).format("Do MMM YYYY HH:mm A");
    return `${dateToRender} UTC`;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch(`${apiUrl}v2/projects/upcoming`)
          .then((response) => response.json())
          .then((res) => {
            const projects = res.data;
            const projectsWithMedia = projects.filter(
              (project) => project?.media?.length > 0
            );

            setFeaturedProjects(projectsWithMedia);
          });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    function playVideo() {
      const video = document.getElementById(`videoHorizontalSlides${index}`);
      // video.load();
      video?.play();
      // console.log(video, 'jdjks');
    }
    playVideo();
  }, [index]);

  return (
    <>
      <Heading>Featured Projects</Heading>
      <CaraouselContainerHorizontal>
        <CustomCarouselHorizontal
          ref={carouselRef}
          dots
          infinite={false}
          infiniteSlide={false}
          slidesToShow={1}
          slidesToScroll={1}
          dotPosition="bottom"
          draggable
          swipeToSlide
          beforeChange={(oldIndex, newIndex) => {
            setIndex(newIndex);
          }}
        >
          {featuredProjects.map((project, i) => {
            if (project?.media[0]?.type === "VIDEO") {
              return (
                <Video
                  key={project._id}
                  id={`videoHorizontalSlides${i}`}
                  muted={isMute}
                  loop={featuredProjects.length === 1}
                  autoPlay
                  loading="lazy"
                  src={project?.media[0]?.url}
                  onEnded={goToNextSlide}

                  // poster={project.projectBannerUrl}
                  // width="85%"
                >
                  Sorry, your browser doesn't support embedded videos, but don't
                  worry, you can{" "}
                  <a href={project?.media[0]?.url}>download it</a>
                  and watch it with your favorite video player!
                </Video>
              );
            }
            // return (
            //   <ImageSlide
            //     key={project._id}
            //     index={i}
            //     src={project.media[0]?.url}
            //   />
            // );
          })}
        </CustomCarouselHorizontal>
        <AbsoluteControls>
          <ControlsRow>
            <VideoTag bgColor={theme.colors.green["400"]}>Featured</VideoTag>
            <VideoTag marginLeft="0" bgColor={theme.colors.yellow["400"]}>
              {featuredProjects[index]?.typeOfProject}
            </VideoTag>
            {featuredProjects[index]?.media[0]?.type === "VIDEO" && (
              <SoundImg
                src={isMute ? muteIcon : volumeUp}
                alt="volume/mute"
                onClick={() => setIsMute(!isMute)}
              />
            )}
          </ControlsRow>
        </AbsoluteControls>

        <AbsoluteVideoInfoHorizontal>
          <VideoTitleRow>
            <ProjectInfoContainer>
              {/* <ProjectLogo
                  src={featuredProjects[index]?.projectLogoUrl || tempLogo}
                  alt="project-logo"
                /> */}
              <ProjectName>{featuredProjects[index]?.projectName}</ProjectName>
            </ProjectInfoContainer>

            <DateTime>
              {featuredProjects[index]?.isTBA
                ? "TBA"
                : convertDateFormat(
                    featuredProjects[index]?.idoDates.roundOneStartDate
                  )}
            </DateTime>
          </VideoTitleRow>
        </AbsoluteVideoInfoHorizontal>
      </CaraouselContainerHorizontal>
    </>
  );
}

export default FeaturedProjects;
