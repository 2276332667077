import React, { useEffect, useState } from 'react';
import UpcommingCard from './UpcommingCard';
import { Heading } from './upcomming.styles';
import { getBackendUrl } from '../../api/constant';
import HorizontalCard from './HorizontalCard/HorizontalCard';
import useWindowSize from '../../../src/hooks/useWindowSize';

function UpcommingCardList() {
  const { width } = useWindowSize();
  const [upcommingData, setUpcommingData] = useState();
  const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);
  useEffect(() => {
    async function fetchData() {
      try {
        await fetch(`${apiUrl}v2/projects/upcoming`)
          .then((response) => response.json())
          .then((res) => setUpcommingData(res));
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  if (upcommingData?.data.length === 1 && width > 850) {
    return (
      <>
        {upcommingData?.data.length !== 0 && (
          <Heading>Upcoming Projects</Heading>
        )}
        <HorizontalCard data={upcommingData?.data} />
      </>
    );
  } else {
  }
  return (
    <>
      {upcommingData?.data.length !== 0 && <Heading>Upcoming Projects</Heading>}
      {upcommingData?.data.length !== 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <UpcommingCard data={upcommingData?.data} />
        </div>
      )}
    </>
  );
}

export default UpcommingCardList;
