import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';
// import { useSelector } from "react-redux";
import moment from 'moment';

import {
  Divider,
  CardContainer,
  CardImgContainer,
  CardLogo,
  DashedDivider,
  ItemRow,
  NameContainer,
  NetworkInfo,
  // PipeDivider,
  ProjectName,
  ProjectType,
  RowText,
  RowTextSpan,
  SupportedTokens,
  TitleRow,
  TokenName,
  BannerImg,
  SliderStyles,
  CarouselStyles,
  PaginationContainer,
  Pagination,
  PaginationDots,
  ArrowContainer,
  ArrowLeft,
  ArrowRight,
  Number,
  Line,
  Hr,
  FlexContainer,
  SlideNumsWrapper,
  SlideNum,
  ButtonsContainer,
  PaginationDivider,
  CustomDot,
} from './upcomming.styles';
// import ProgressBar from "../ProgressBar/ProgressBar";
import NetworkImg from '../NetworkImg/NetworkImg';
import Arrow from '..//../assets/Arrow.svg';
import arrowDark from '..//../assets/arrowDark.svg';
import ForwardArrowDark from '..//../assets/ForwardArrowDark.svg';
import ForwardArrowLite from '..//../assets/ForwardArrowLite.svg';
import LineLight from '..//../assets/LineLight.svg';
import LineDark from '..//../assets/LineDark.svg';
import arrowDarkRight from '..//../assets/arrowDarkRight.svg';
import arrowLightLeft from '..//../assets/arrowLightLeft.svg';
// import WhitelistTag from "../WhitelistTag/WhitelistTag";
// import VerticalCardFooter from "./components/VerticalCardFooter";
// import { Web3Class } from "../../Web3/web3Class";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTheme } from 'styled-components';

import { getBackendUrl } from '../../api/constant';
// eslint-disable-next-line react/prop-types
// import { theme } from "../../theme/theme";
function UpcommingCard(data) {
  const theme = useTheme();

  const [index, setIndex] = useState(0);
  let Maxindex = data?.data?.length;

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <img src={Arrow} className={className} onClick={onClick} />;
  }
  const CarouselRef = useRef();
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (theme.mode === 'dark') {
      return (
        <img src={ForwardArrowLite} className={className} onClick={onClick} />
      );
    }
    return (
      <img src={ForwardArrowLite} className={className} onClick={onClick} />
    );
  }
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1100, itemsToShow: 3, itemsToScroll: 3 },
  ];
  // const goToNextSlide = () => {
  //   // console.log('GoToNext');
  //   CarouselRef.current.slideNext();

  //   // console.log('Nothing');
  // };
  // const goToPrevSlide = () => {
  //   // console.log('GoToNext');
  //   CarouselRef.current.slidePrev();

  //   // console.log('Nothing');
  // };
  function convertDateFormat(d) {
    const dateToRender = moment.utc(d).format('Do MMM YYYY | HH:mm A');
    return `${dateToRender} UTC`;
  }
  const gotoPrevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
    CarouselRef.current.slidePrev();
  };

  const gotoNextSlide = () => {
    CarouselRef.current.slideNext();
    if (index < CarouselRef.current.getNumOfPages() - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <>
      <div style={{ width: '100%', position: 'relative' }}>
        <CarouselStyles
          breakPoints={breakPoints}
          ref={CarouselRef}
          Mobilelength={data?.data?.length === 2}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <FlexContainer
                disable={data?.data?.length <= 3}
                show={data?.data?.length === 1}
              >
                {pages.map((page) => {
                  const isActivePage = activePage === page;
                  return (
                    <CustomDot
                      key={page}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                    />
                  );
                })}
                <PaginationDivider />

                <ButtonsContainer>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => gotoPrevSlide()}
                    src={
                      theme.mode === 'dark' ? arrowLightLeft : ForwardArrowDark
                    }
                    alt=''
                  />
                  <SlideNumsWrapper>
                    <SlideNum>
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </SlideNum>{' '}
                    <SlideNum opacity='0.5'>
                      {pages.length < 10 ? `0${pages.length}` : pages.length}{' '}
                    </SlideNum>
                  </SlideNumsWrapper>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => gotoNextSlide()}
                    src={theme.mode === 'dark' ? arrowDarkRight : arrowDark}
                    alt=''
                  />
                </ButtonsContainer>
              </FlexContainer>
            );
          }}
        >
          {data?.data?.map((list) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '30px',
                    width: '100%',
                  }}
                >
                  <a
                    href={`https://app.metalaunch.io/pools/${list?._id}`}
                    target='_blank'
                  >
                    <CardContainer>
                      <CardImgContainer>
                        <BannerImg
                          src={list.projectBannerUrl}
                          alt={list.projectName}
                        />
                        <ProjectType type={list.typeOfProject}>
                          {list.typeOfProject}
                        </ProjectType>
                      </CardImgContainer>

                      <div className='details-container'>
                        <CardLogo>
                          <img
                            src={list.projectLogoUrl}
                            alt={list.projectName}
                          />
                        </CardLogo>
                        <TitleRow>
                          <NameContainer>
                            <ProjectName>{list.projectName}</ProjectName>
                            <TokenName>{list.tokenDetails.tokenName}</TokenName>
                          </NameContainer>
                          <NetworkInfo>
                            <NetworkImg
                              networkId={list.projectNetwork.networkId}
                            />
                            {/* <PipeDivider>|</PipeDivider> */}
                            <SupportedTokens>
                              {list.supportedToken}
                            </SupportedTokens>
                          </NetworkInfo>
                        </TitleRow>

                        <ItemRow>
                          <RowText>Total Raise</RowText>
                          <DashedDivider
                            strLength={
                              list.tokenDetails.totalFundraise.toString().length
                            }
                          />
                          <RowText>
                            {list.tokenDetails.totalFundraise === 0
                              ? 'TBA'
                              : `${list.tokenDetails.totalFundraise.toLocaleString()} USD`}
                          </RowText>
                        </ItemRow>
                        <ItemRow>
                          <RowText>Sale Date</RowText>
                          <DashedDivider
                            strLength={
                              list.tokenDetails.tokenSaleDate.toString().length
                            }
                          />
                          <RowText>
                            {list.isTBA
                              ? 'TBA'
                              : convertDateFormat(
                                  list.tokenDetails.tokenSaleDate
                                )}
                          </RowText>
                        </ItemRow>

                        <Divider />
                      </div>
                    </CardContainer>
                  </a>
                </div>
              </>
            );
          })}
        </CarouselStyles>
      </div>
    </>
  );
}

export default UpcommingCard;
