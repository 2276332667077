import React, { useState } from "react";
// import "./partnersWebEconomy.css";
// import { theme } from "../../theme/theme";
import Slider from "react-slick";
import {
  Container,
  Heading,
  IconsMainContainer,
  IconContainer,
  Image,
  ContainerHeading,
  IconsSlideContainer,
  SliderStyles,
  CarouselStyles,
  Heading2,
  ImageBanner
} from "./PartenersWeb3Economy.styles";

import blackdragonblack from "../../assets/blackdragonblack.png";
import blackdragonwhite from "../../assets/blackdragonwhite.png";
import dutchblack from "../../assets/dutchblack.png";
import dutchwhite from "../../assets/dutchwhite.png";
import mhventuresblack from "../../assets/mhventuresblack.png";
import mhventureswhite from "../../assets/mhventureswhite.png";

import Arrow from "..//../assets/Arrow.svg";
import arrowDark from "..//../assets/arrowDark.svg";
import ForwardArrowDark from "..//../assets/ForwardArrowDark.svg";
import ForwardArrowLite from "..//../assets/ForwardArrowLite.svg";
import whiteThemeArrow from "..//../assets/whiteThemeArrow.svg";
import polyStarter from "./assets/polyStarter.png";
import polystarterBlack from "./assets/Black/polystarterBlack.png";
import BlackDragon from "./assets/BlackDragon.png";
import R8 from "./assets/R8.png";
import R8Black from "./assets/Black/R8Black.png";
import Biconomy from "./assets/Biconomy.png";
import BiconomyBlack from "./assets/Black/BiconomyBlack.png";
import RocketOne from "./assets/RocketOne.png";
import RocketOneBlack from "./assets/Black/RocketOneBlack.png";
import MetaExplorer from "./assets/MetaExplorer.png";
import MetaExplorerBlack from "./assets/Black/MetaExplorerBlack.png";
import DreamBoat from "./assets/DreamBoat.png";
import DreamBoatBlack from "./assets/Black/DreamBoatBlack.png";
import Excalibur from "./assets/Excalibur.png";
import ExcaliburBlack from "./assets/Black/ExcaliburBlack.png";
import Floem from "./assets/Floem.png";
import FloemBlack from "./assets/Black/FloemBlack.png";
import Dutch from "./assets/Dutch.png";
import DutchBlack from "./assets/Black/DutchBlack.png";
import Meridan from "./assets/Meridan.png";
import MeridanBlack from "./assets/Black/MeridanBlack.png";
import BTCMonday from "./assets/BTCMonday.png";
import BTCMondayBlack from "./assets/Black/BTCMondayBlack.png";
import Atesis from "./assets/Atesis.png";
import AtesisBlack from "./assets/Black/AtesisBlack.png";
import Alpha from "./assets/Alpha.png";
import AlphaBlack from "./assets/Black/AlphaBlack.png";
import Sky from "./assets/Sky.png";
import SkyBlack from "./assets/Black/SkyBlack.png";
import AZ from "./assets/AZ.png";
import AZBlack from "./assets/Black/AZBlack.png";
import Fish from "./assets/Fish.png";
import FishBlack from "./assets/Black/FishBlack.png";
import Lotus from "./assets/Lotus.png";
import LotusBlack from "./assets/Black/LotusBlack.png";
import TC from "./assets/TC.png";
import TCBlack from "./assets/Black/TCBlack.png";
import ICPO from "./assets/ICPO.png";
import ICPOBlack from "./assets/Black/ICPOBlack.png";
import HG from "./assets/HG.png";
import HGBlack from "./assets/Black/HGBlack.png";
import ZC from "./assets/ZC.png";
import ZCBlack from "./assets/Black/ZCBlack.png";
import crypto from "./assets/crypto.png";
import cryptoBlack from "./assets/Black/cryptoBlack.png";
import SL2 from "./assets/SL2.png";
import SL2Black from "./assets/Black/SL2Black.png";
import CL from "./assets/CL.png";
import CLBlack from "./assets/Black/CLBlack.png";
import synapse from "./assets/synapse.png";
import synapseBlack from "./assets/Black/synapseBlack.png";
import Aha from "./assets/Aha.png";
import AhaBlack from "./assets/Black/AhaBlack.png";
import lionswhite from "./assets/lionswhite.png";
import lionsblack from "./assets/Black/lionsblack.png";
import F from "./assets/F.png";
import FBlack from "./assets/Black/FBlack.png";
import VC from "./assets/VC.png";
import VCBlack from "./assets/Black/VCBlack.png";
import enzacresearchwhite from "./assets/enzacresearchwhite.png";
import enzacresearchblack from "./assets/Black/enzacresearchblack.png";
import Vestor from "./assets/Vestor.png";
import VestorBlack from "./assets/Black/VestorBlack.png";
import CFZ from "./assets/CFZ.png";
import CFZBlack from "./assets/Black/CFZBlack.png";
import X from "./assets/X.png";
import XBlack from "./assets/Black/XBlack.png";
import nextwhite from "./assets/nextwhite.png";
import nextzventuresblack from "./assets/Black/nextzventuresblack.png";
import vespertine from "./assets/vespertine.png";
import vespertineBlack from "./assets/Black/vespertineBlack.png";
import er from "./assets/er.png";
import erBlack from "./assets/Black/erBlack.png";
import CV from "./assets/CV.png";
import CVBlack from "./assets/Black/CVBlack.png";
import A2A from "./assets/A2A.png";
import A2ABlack from "./assets/Black/A2ABlack.png";
import Mirmi from "./assets/Mirmi.png";
import MirmiBlack from "./assets/Black/MirmiBlack.png";
import cryptoin from "./assets/cryptoin.png";
import cryptoinBlack from "./assets/Black/cryptoinBlack.png";
import converse from "./assets/converse.png";
import converseBlack from "./assets/Black/converseBlack.png";
import DCT from "./assets/DCT.png";
import DCTBlack from "./assets/Black/DCTBlack.png";
import Hero from "./assets/Hero.png";
import HeroBlack from "./assets/Black/HeroBlack.png";
import X99 from "./assets/X99.png";
import X99Black from "./assets/Black/X99Black.png";
import Web from "./assets/Web.png";
import WebBlack from "./assets/Black/WebBlack.png";
import CM from "./assets/CM.png";
import CMBlack from "./assets/Black/CMBlack.png";
import Herd from "./assets/Herd.png";
import HerdBlack from "./assets/Black/HerdBlack.png";
import unrealwhite from "./assets/unrealwhite.png";
import unrealBlack from "./assets/Black/unrealBlack.png";
import chain from "./assets/chain.png";
import chainBlack from "./assets/Black/chainBlack.png";
import cryptok from "./assets/Black/cryptok.png";
import cryptokwhite from "./assets/cryptokwhite.png";
import crytov from "./assets/crytov.png";
import cryptovBlack from "./assets/Black/cryptovBlack.png";
import pandora from "./assets/pandora.png";
import pandoraBlack from "./assets/Black/pandoraBlack.png";
import abventuresblack from "./assets/Black/abventuresblack.png";
import abventureswhite from "./assets/abventureswhite.png";
import ICI from "./assets/ICI.png";
import ICIBlack from "./assets/Black/ICIBlack.png";
import One from "./assets/One.png";
import OneBlack from "./assets/Black/OneBlack.png";
import OX from "./assets/OX.png";
import OXBlack from "./assets/Black/OXBlack.png";
import unicorn from "./assets/unicorn.png";
import unicornBlack from "./assets/Black/unicornBlack.png";
import harmonylauncherwhite from "./assets/harmonylauncherwhite.png";
import harmonylauncherblack from "./assets/Black/harmonylauncherblack.png";
import gems from "./assets/gems.png";
import gemsBlack from "./assets/Black/gemsBlack.png";
import PlayPad from "./assets/PlayPad.png";
import PlayPadBlack from "./assets/Black/PlayPadBlack.png";
import KommunitasBlack from "./assets/Black/KommunitasBlack.png";
import kommunitaswhite from "./assets/kommunitaswhite.png";
import spares from "./assets/spares.png";
import sparesBlack from "./assets/Black/sparesBlack.png";
import Y from "./assets/Y.png";
import YBlack from "./assets/Black/YBlack.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "styled-components";
function PartnersWeb3Economy() {
  const [currentSlide, setCurrentSlide] = useState();
  const theme = useTheme();
  var settings = {
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    // autoplay={true}
    autoplaySpeed: 3000,
    dotPosition: "bottom",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (theme.mode === "dark") {
      return <img src={Arrow} className={className} onClick={onClick} />;
    }
    return <img src={arrowDark} className={className} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (theme.mode === "dark") {
      return (
        <img src={ForwardArrowLite} className={className} onClick={onClick} />
      );
    }
    return (
      <img src={ForwardArrowDark} className={className} onClick={onClick} />
    );
  }
  // if (window.width < 800) {
  //   var settings = {
  //     dots: true,
  //     slidesToShow: 3,
  //     slidesToScroll: 4,
  //     // autoplay={true}
  //     autoplaySpeed: 3000,
  //     dotPosition: "bottom",
  //   };
  // }

  // const breakpoints = {
  //   width: 1,
  //   itemsToShow: 1,
  //   width: 550,
  //   itemsToShow: 3,
  //   width: 768,
  //   itemsToShow: 4,
  //   width: 1200,
  //   itemsToShow: 5,
  // };

  return (
    <Container>
      <ContainerHeading>
        <Heading>Building the Web3 Economy Together</Heading>
        <Heading2>Partners</Heading2>
      </ContainerHeading>
      <IconsMainContainer>
        <SliderStyles {...settings}>
          <IconsSlideContainer style={{ width: "100%" }}>
            {theme.mode === "dark" ? (
                <ImageBanner>

                  <img
                   src={polyStarter}
                   style={{ margin: "0 auto", width: "100%", height: "100%" }}
                 />
                </ImageBanner>
           
            ) : (
              <ImageBanner>

                <img
                  src={polystarterBlack}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            )}
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={BlackDragon}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={blackdragonblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={R8}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={R8Black}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {theme.mode === "dark" ? (
              <ImageBanner>

                <img
                  src={mhventureswhite}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            ) : (
              <ImageBanner>

                <img
                  src={mhventuresblack}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            )}
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Biconomy}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={BiconomyBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={RocketOne}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={RocketOneBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            
            {theme.mode === "dark" ? (
              <ImageBanner>
              
                <img
                  src={MetaExplorer}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
            </ImageBanner>
            ) : (
              <ImageBanner>

                <img
                  src={MetaExplorerBlack}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            )}
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={DreamBoat}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={DreamBoatBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Excalibur}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={ExcaliburBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
           
          </IconsSlideContainer>

          <IconsSlideContainer style={{ width: "100%" }}>
            {theme.mode === "dark" ? (
              <ImageBanner>

                <img
                  src={Floem}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            ) : (
              <ImageBanner>

                <img
                  src={FloemBlack}
                  style={{ margin: "0 auto", width: "100%", height: "100%" }}
                />
              </ImageBanner>
            )}
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Dutch}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={DutchBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Meridan}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={MeridanBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={BTCMonday}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={BTCMondayBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Atesis}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={AtesisBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Alpha}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={AlphaBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Sky}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={SkyBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Fish}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={FishBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={AZ}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={AZBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Lotus}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={LotusBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={ICPO}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={ICPOBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={TC}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={TCBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={crypto}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={cryptoBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={HG}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={HGBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={ZC}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={ZCBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={SL2}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={SL2Black}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={CL}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={CLBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={synapse}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={synapseBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Aha}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={AhaBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={lionswhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={lionsblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={F}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={FBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
            
        
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={VC}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={VCBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={enzacresearchwhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={enzacresearchblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Vestor}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={VestorBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={CFZ}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={CFZBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={X}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={XBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={nextwhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={nextzventuresblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={vespertine}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={vespertineBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={er}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={erBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={CV}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={CVBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          
            
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={A2A}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={A2ABlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={cryptoin}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={cryptoinBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Mirmi}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={MirmiBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={converse}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={converseBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={DCT}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={DCTBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Hero}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={HeroBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={X99}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={X99Black}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
          {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Web}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={WebBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={CM}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={CMBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Herd}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={HerdBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={unrealwhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={unrealBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={chain}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={chainBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={crytov}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={cryptovBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={cryptokwhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={cryptok}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={pandora}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={pandoraBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={abventureswhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={abventuresblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={ICI}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={ICIBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={One}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={OneBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={OX}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={OXBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
                {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={unicorn}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={unicornBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={harmonylauncherwhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={harmonylauncherblack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
             {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={gems}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={gemsBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={PlayPad}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={PlayPadBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
              {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={kommunitaswhite}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={KommunitasBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
          <IconsSlideContainer style={{ width: "100%" }}>
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={spares}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={sparesBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
            {
              theme.mode === "dark" ? 
               <ImageBanner>
                  <img
              src={Y}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>:
               <ImageBanner>
                  <img
              src={YBlack}
              style={{ margin: "0 auto", width: "100%", height: "100%" }}
            />
               </ImageBanner>
             }
           
          </IconsSlideContainer>
        </SliderStyles>
        {/* <Image src={Arrow} onClick={(e) => setCurrentSlide("2")} /> */}
      </IconsMainContainer>
    </Container>
  );
}

export default PartnersWeb3Economy;
