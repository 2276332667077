import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding-top: 3rem;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  display: grid;
  grid-template-columns: 50% 50%;
  padding-right: 20px;
  padding-left: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
`;

export const LeftContainer = styled.div``;
export const RightContainer = styled.div`
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px 0;
  }
`;
export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 40px;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: ${(props) => (props.theme.mode === "dark" ? "#fff" : "#000000")};
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const Para = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  color: ${(props) => (props.theme.mode === "dark" ? "#fff" : "#31374D")};

  flex: none;
  order: 1;
  flex-grow: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px 0;
  }
`;
export const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;
export const Input = styled.input`
  background: #fa539f;
  border-radius: 12px;
  border: none;
  padding: 1rem;
  width: 100%;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #e7eaf3;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    /* identical to box height, or 28px */

    /* V2/Light/200 */

    color: #e7eaf3;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    /* identical to box height, or 28px */

    /* V2/Light/200 */

    color: #e7eaf3;
  }
`;
export const Button = styled.button`
  padding: 0.7rem;

  /* V2/Light/100 */

  background: #ffffff;
  border-radius: 8px;
  border: none;
  position: absolute;
  right: 5px;
  top: 4px;
`;
export const Icon = styled.img``;
export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  /* or 34px */

  /* V2/Dark/200 */

  color: ${(props) => (props.theme.mode === "dark" ? "#fff" : "#31374D")};

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
