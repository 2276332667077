/* eslint-disable */
// see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-importdotenv.config()
import axios from "axios";
// import * as dotenv from "dotenv";
// dotenv.config();
const BASE_URL = "http://dev-admin-api.metalaunch.io/";

export const getBackendUrl = (envVar) => {
  if (envVar?.toUpperCase().trim() === "LOCAL") {
    return "http://dev-admin-api.metalaunch.io/";
  }
  if (envVar?.toUpperCase().trim() === "DEV") {
    return "http://dev-admin-api.metalaunch.io/";
  }
  if (envVar?.toUpperCase().trim() === "QA") {
    return "http://qa-admin-api.metalaunch.io/";
  }
  if (envVar?.toUpperCase().trim() === "PROD") {
    return "https://api.metalaunch.io/";
  }
  return null;
};

const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

class ApiCall {
  post = (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + url, data)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  get = (url) =>
    new Promise((resolve, reject) => {
      var headers = {
        Authorization: "Bearer " + token,
      };

      axios
        .get(apiUrl + url, { headers })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });

  getWithoutHeaders = (url) =>
    new Promise((resolve, reject) => {
      axios
        .get(apiUrl + url)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });

  postReplace = (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + url, data)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  getReplace = (url) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + url)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

export default new ApiCall();
