import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4rem 0rem;
  @media (max-width: 587px) {
    padding: 4rem 0rem 7rem 0;
  }
`;
export const Para = styled.div`
  letter-spacing: -1px;
  text-transform: capitalize;

  /* grad */

  background: linear-gradient(94.18deg, #ff0074 0%, #6038f7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const ContainerLeft = styled.div`
  padding-bottom: 7rem;
  flex: 1.6;
  @media (max-width: 587px) {
    padding-bottom: 0rem;
    width: 100%;
  }
`;
export const HeaderTop = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
  /* or 72px */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  text-transform: capitalize;
  letter-spacing: -1px;
  text-transform: capitalize;

  /* grad */

  background: linear-gradient(94.18deg, #ff0074 0%, #6038f7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: ${(props) => (props.theme.mode === "dark" ? "#FFFFFF" : " #31374D")};

  @media (max-width: 587px) {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
`;
export const Header1 = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  /* or 72px */

  display: flex;
  align-items: center;
  letter-spacing: -1px;
  text-transform: capitalize;
  @media (max-width: 587px) {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 145%;
  /* or 29px */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  // max-width: 70%;
  /* V2/Light/100 */

  color: ${(props) => (props.theme.mode === "dark" ? "#FFFFFF" : "#777D92")};
  @media (max-width: 587px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    margin: 0.5rem 0;
  }
`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 587px) {
    margin-top: 1rem;
  }
`;
export const ButtonLeft = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  gap: 8px;
  z-index: -1;
  line-height: 145%;
  width: 152px;
  height: 50px;

  /* V2/Primary/500 */

  background: #ff0074;
  border: none;
  border-radius: 28px;
  color: white;
  @media (max-width: 900px) {
    font-size: 14px;
    padding: 0px;
    margin-right: 10px;
  }
  @media (max-width: 587px) {
    font-size: 14px;
    padding: 0px;
    margin-right: 10px;
  }
`;
export const ButtonRight = styled.button`
  padding: 12px;
  gap: 8px;

  line-height: 145%;
  width: 152px;
  height: 50px;

  /* V2/Primary/500 */

  border: 1px solid #ff0074;
  border-radius: 200px;
  background: transparent;
  color: ${(props) => (props.theme.mode === "dark" ? " #E7EAF3" : "#000000")};
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 587px) {
    font-size: 14px;
  }
  &:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;

    line-height: 145%;
    width: 152px;
    height: 50px;

    /* V2/Primary/500 */

    background: #ff0074;
    border: none;
    border-radius: 28px;
    color: white;
  }
`;
export const ContainerRight = styled.div`
  flex: 1;
  @media (max-width: 1127px) {
    display: none;
  }
`;
export const HoverDiv = styled.div`
  background: #191c27;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
  position: absolute;
  top: 51px;
`;
export const HoverImg1 = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;
export const HoverImg2 = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    // padding: 2px 2px 2px 2px;
  }
  padding: 10px 2px 2px 2px;
  border-top: 1px solid #616c97;
`;
export const ButtonImg = styled.img`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
    margin-left: 5px;
  }
`;
