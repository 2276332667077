/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { darkTheme, lightTheme } from "../../theme/theme";

export const initialState = {
  value: "light",
  props: lightTheme,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,

  reducers: {
    toggleTheme: (state) => {
      if (state.value === "dark") {
        return { ...state, value: "light", props: lightTheme };
      }
      return {
        ...state,
        value: "dark",
        props: darkTheme,
      };
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
