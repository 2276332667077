import React from "react";
import {
  Container,
  ContainerHeading,
  ContainerPara,
  ContainerHoverCircles,
  ContainerHoverCircleDiv,
  ContainerHoverCircleContent,
  // ContainerHoverCircleContentt,
  ContainerHoverCircle,
  ContainerHoverCircleImg,
} from "./AsvaTokenUtility.styles";
import rocket from "./assets/rocket.png";
import discount from "./assets/tv.png";
import flower from "./assets/locker.png";
import bank from "./assets/bank.png";
import gift from "./assets/gift.png";

function AsvaTokenUtility() {
  return (
    <Container>
      <ContainerHeading>Asva Token utility</ContainerHeading>
      <ContainerPara>
        The ASVA token is a utility and governance token of the ASVA ecosystem.
        The ASVA token is designed to incentivize holders with various benefits
        for using ecosystem products.
      </ContainerPara>
      <ContainerHoverCircleDiv>
        <ContainerHoverCircles>
          <ContainerHoverCircle>
            <ContainerHoverCircleImg src={rocket} />

            <ContainerHoverCircleContent>
              Launchpad access to participate IDOs
            </ContainerHoverCircleContent>
          </ContainerHoverCircle>
        </ContainerHoverCircles>
        <ContainerHoverCircles>
          <ContainerHoverCircle>
            <ContainerHoverCircleImg src={bank} />

            <ContainerHoverCircleContent>
              Governance rights in decision making
            </ContainerHoverCircleContent>
          </ContainerHoverCircle>
        </ContainerHoverCircles>
        <ContainerHoverCircles>
          <ContainerHoverCircle>
            <ContainerHoverCircleImg src={flower} />

            <ContainerHoverCircleContent>
              Staking & Farming Incentivisation
            </ContainerHoverCircleContent>
          </ContainerHoverCircle>
        </ContainerHoverCircles>
        <ContainerHoverCircles>
          <ContainerHoverCircle>
            <ContainerHoverCircleImg src={gift} />

            <ContainerHoverCircleContent>
              NFTs Rewards for $ASVA Holders
            </ContainerHoverCircleContent>
          </ContainerHoverCircle>
        </ContainerHoverCircles>
        <ContainerHoverCircles>
          <ContainerHoverCircle>
            <ContainerHoverCircleImg type="discount" src={discount} />

            <ContainerHoverCircleContent>
              Discounted Transaction Fees on ASVA Marketplace
            </ContainerHoverCircleContent>
          </ContainerHoverCircle>
        </ContainerHoverCircles>
      </ContainerHoverCircleDiv>
    </Container>
  );
}

export default AsvaTokenUtility;
