import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  margin: 10px 0 20px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftleftContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #777d92;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 12px;
  }
`;

export const LeftrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-top: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    gap: 0.8rem;
  }
`;

export const LeftrightContainerImage = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0rem;
  }
`;

export const LeftrightConatinerImg = styled.img`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: 80px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.75rem;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: row-reverse;
  }
`;

export const RightContainerTextBox = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #777d92;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 12px;
  }
`;

export const RightContainerDivider = styled.div`
  .divider {
    height: 30px;
    /* padding-bottom: 10px; */
    width: 20%;
    /* margin: 20px auto; */
    float: left;
  }
  .inner {
    /* margin-top: 19px; */
    border-bottom: 2px dotted #b38b0d;
  }
`;

export const DashedDivider = styled.hr`
  flex: 1;
  margin: 0 20px;
  border: none;
  height: 1px;
  background: #777d92;
  width: 48px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: 25px;
  }
`;

export const RightrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const RightrightContainerImage = styled.div``;

export const RightrightContainerImg = styled.img`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: 36px;
  }
`;
