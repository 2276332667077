import React from "react";
import { useTheme } from "styled-components";
import {
  FotterBottomLinksContainer,
  FotterBottomLinksContainerLeft,
  FotterBottomLinksContainerRight,
  Heading,
  Links,
  Link,
} from "./FotterBottomLinks.styles";
import Link1Dark from "../assets/Link1Dark.svg";
// import Link2Dark from "../assets/Link2Dark.svg";
import Link2Dark from "../assets/QuillAudits Horizontal White.svg";
// import Link2Light from "../assets/Link2Light.svg";
import Link2Light from "../assets/QuillAudits Horizontal Blue.svg";
import Link1Light from "../assets/Link1Light.svg";

function FotterBottomLinks() {
  const theme = useTheme();
  return (
    <FotterBottomLinksContainer>
      <FotterBottomLinksContainerLeft>
        © 2022 Asva Labs LLC . All Rights Reserved.
      </FotterBottomLinksContainerLeft>
      <FotterBottomLinksContainerRight>
        <Heading>Security Partners</Heading>
        <Links>
          <a target="_blank" href="https://hacken.io/" rel="noreferrer">
            <Link src={theme.mode === "dark" ? Link1Dark : Link1Light} />
          </a>
          <a
            target="_blank"
            href="https://audits.quillhash.com/smart-contract-audit"
            rel="noreferrer"
          >
            <Link src={theme.mode === "dark" ? Link2Dark : Link2Light} />
          </a>
        </Links>
      </FotterBottomLinksContainerRight>
    </FotterBottomLinksContainer>
  );
}

export default FotterBottomLinks;
