import styled from 'styled-components';

export const FooterDivFull = styled.div`
  padding: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 15px;
  }
`;

export const FooterMainDiv = styled.div`
  /* background-color: #191c27; */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    overflow: hidden;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 2rem;
  }
`;
export const Conatiner = styled.div`
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 18px;
  margin-top: 6rem;
  padding-bottom: 3rem;
`;
export const Conatiner2 = styled.div`
  max-width: 1130px;
  margin: auto;
`;

export const DivImageAndPContainer = styled.div`
  position: relative;
  margin-top: 0 !important  ;
  margin: 70px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 10px;
  }
`;
export const ImageDiv = styled.div`
  margin-bottom: 12px;
  width: 178px;
`;
export const PTag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 165%;
  color: ${(props) =>
    props.theme.mode === 'dark' ? 'rgba(231, 234, 243, 0.64)' : '#777D92'};
  /* position: absolute;
  top: 50%; */

  border-top: 1px solid
    ${(props) => (props.theme.mode === 'dark' ? '#e7eaf3' : '#B8C1DA')};
  padding-top: 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 500px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const FooterDivTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  margin: 40px 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px;
    gap: 25px;
  }
`;

export const FooterDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};

  @media (min-width: ${(props) =>
      props.theme.breakpoints.tablet}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop}) {
    padding: 13px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 25px 10px 25px 10px;
    flex: 1 0 25%;
  }
`;

export const FooterHiddenForSmallScreen = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const FooterP3 = styled.p`
  padding: 10px;
  margin: 0;
`;

export const FooterDivInsider = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};

  cursor: pointer;
  /*
  &:hover {
    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
  } */

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['300']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 12px 10px 12px 10px;
  }
`;

export const FooterAnchor = styled.a`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};

  cursor: pointer;
  text-decoration: none;
  width: max-content;

  &:hover {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['500']};
  }

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['300']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 11px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 11px;
  }
`;
