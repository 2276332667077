import styled from "styled-components";

export const FooterDiv = styled.div`
  display: flex;
  display:none;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  /* padding: 100px 40px 65px 10px; */
  /* border-bottom: 1px solid #31374d; */
  background: linear-gradient(96.41deg, #ff0074 1.22%, #6038f7 100%);
  border-radius: 20px;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.black[200]
        : props.theme.colors.grey[200]};
  margin-top: 6rem;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0;
    flex-direction: column;
    margin: 40px 0;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 341px;
    padding: 0px 5px;

    flex-direction: column;
    margin: 40px 0px;
    width: 100%;
  }
`;

export const FooterInputDivColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.tablet}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop}) {
    margin-left: 2rem;
    padding: 15px 0;
  }
`;
export const FooterP = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  /* font-size: 28px; */
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -1px;
  text-transform: capitalize;
  line-height: 140%;
  color: #ffffff;

  opacity: 0.9;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 22px;
    padding: 0 10px;
    margin-left: 8px;
    color: #ffffff;
    align-items: center;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
`;
export const FooterP1 = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  /* font-size: 28px; */
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -1px;
  text-transform: capitalize;
  line-height: 140%;
  color: #ffffff;

  opacity: 0.9;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 22px;
    padding: 0 10px;
    margin-left: 8px;
    color: #ffffff;
    align-items: center;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
  margin: 0;
`;

export const FooterP2 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-top: 16px;
  text-align: start;
  /* color: #ffffff; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.grey["100"]};
  opacity: 0.7;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 16px;
    padding-top: 10px;
    padding: 0 10px;
    margin-left: 8px;
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    margin-bottom: 1rem;
  }
`;
export const FooterP3 = styled.p`
  padding: 0 0 10px 0;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["300"]};

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
`;

export const FooterDivInput = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: max-content;
  }
`;

export const FooterInput = styled.input`
  outline: none;
  background: #fa539f;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.grey["200"]};
  /* flex: 1; */
  padding: 27px 20px;
  font-size: 1.1em;
  font-weight: 400;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: none;
  border: none;
  ::placeholder {
    color: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.grey["100"]
        : props.theme.colors.grey["100"]};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.grey["100"]
        : props.theme.colors.grey["100"]};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.grey["100"]
        : props.theme.colors.grey["100"]};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 2.1em;
    padding: 20px 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* margin: 0 0 0 5px; */
    width: inherit;
    /* padding: 25px 10px 25px 15px; */
    font-size: 1rem;
  }
`;
export const FooterInputBtn = styled.button`
  padding-right: 10px;
  background: #fa539f;
  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  cursor: pointer;
  cursor: hand;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 3px;
  }
`;
export const FooterInputBtnspan = styled.span`
  margin-left: 40px;
  padding: 18px 31px;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #191c27;
  background: #ffffff;
  border-radius: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: max-content;
    /* padding: 18px 5px; */
    font-size: 1rem;
    /* margin: 0px; */

    padding: 13px 18px;
    /* font-size: 1.8em; */
    margin-left: auto;
    border-radius: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* padding: 18px 5px; */
    font-size: 1rem;
    /* margin: 0px; */

    padding: 10px 5px;
    /* font-size: 1.8em; */

    border-radius: 12px;
  }
`;

export const FooterArrowImage = styled.img`
  margin-left: 10px;
  display: initial;
  max-width: unset;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: initial;
    max-width: unset;
    display: none;
  }
`;

export const MobileImage = styled.img`
  display: none;
  width: 160px;
  height: 161px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: inline;
  }
`;
export const DesktopImage = styled.img`
  display: inline;
  max-width: 27rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
`;
export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-top: 16px;
  text-align: start;
  /* color: #ffffff; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["400"]};
  opacity: 0.7;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-left: 1rem;
  }
`;
