import { Carousel } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  /* padding: 4rem 2rem; */
  z-index: -1;
`;
export const Heading = styled.p`
  font-size: 2.3em;
  margin-top: 5rem;
  font-weight: 700;
  color: #fff;
  width: 100%;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["300"]};
      letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 30%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Video = styled.video`
  border-radius: 20px;
  z-index: -1;
  background: linear-gradient(
    175.13deg,
    #191c27 3.93%,
    rgba(25, 28, 39, 0) 85.37%
  );

  margin: auto;
  height: 500px;
  /* max-height: 75vh; */
  width: 100vw;
  object-fit: fill;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: 300px;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 200px;
  }
`;

export const VideoTitleRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProjectName = styled.h2`
  margin: 0;
  font-size: 2em;
  color: ${(props) => props.theme.colors.grey["200"]};
  font-weight: 600;
`;

export const DateTime = styled.p`
  margin: 0;
  margin-right: 3rem;
  padding: 0.5rem;
  width: fit-content;
  border: 1px solid #31374d;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  background: ${(props) => props.theme.colors.black["300"]};
  color: ${(props) => props.theme.colors.grey["200"]};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 1rem;
    font-size: 1.5em;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.2em;
  }
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3rem;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 1rem;
  }
`;

export const AbsoluteControls = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
`;

export const ControlsRow = styled.div`
  display: flex;
  align-items: center;
`;

export const VideoTag = styled.div`
  font-weight: 500;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.colors.black["400"]};
  border-radius: 24px;
  padding: 0.5rem 1rem;
  margin: auto 1rem;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1.5em;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* padding: 0.2rem 1rem; */
    height: 2rem;
    margin: auto 0.5rem;
  }
`;

export const SoundImg = styled.img`
  width: 42px;
  height: 42px;
  align-self: center;
  margin-right: 1rem;
  margin-left: auto;
  padding: 5px;
  &:hover {
    cursor: pointer;
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-right: 0.5rem;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0.4rem;
    width: 35px;
    height: 35px;
  }
`;

export const ImageSlide = styled.img`
  border-radius: 20px;
  margin: auto;
  width: 100%;

  background: linear-gradient(
    175.13deg,
    #191c27 3.93%,
    rgba(25, 28, 39, 0) 85.37%
  );
  height: 475px;
  max-height: 75vh;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: 300px;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 200px;
  }
`;

export const CustomCarouselHorizontal = styled(Carousel)`
  width: 100%;

  > .ant-carousel {
    width: 100%;
    height: 40rem;
  }

  > .slick-list {
    width: 100%;
  }
  > .slick-dots-bottom {
    top: 102%;
    margin-left: 0;
  }
  > .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.grey["100"]
        : props.theme.colors.grey["300"]};
  }
  > .slick-dots li.slick-active button {
    width: 20px;
    height: 8px;
    border-radius: 10px;
    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
  }

  > .slick-dots li button:before {
    opacity: 0;
  }
  > .slick-dots li button:hover {
    opacity: 0;
  }
`;

export const CaraouselContainerHorizontal = styled.div`
  position: relative;
  width: 100%;
  margin: auto;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const AbsoluteVideoInfoHorizontal = styled.div`
  position: absolute;
  /* bottom: 50px; */
  top: 85%;
  width: 100%;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: 80%;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 70%;
  }
`;
