import React, { useEffect, useState } from "react";
import FeaturedProjects from "../components/FeaturedProjects/FeaturedProjects";
import EndedProjects from "../components/EndedProjects/EndedProjects";
import styled from "styled-components";
import AsvaTokenUtility from "../AsvaTokenUtility/AsvaTokenUtility";
import Navbar from "../components/Navbar/Navbar";
import FooterBottom from "../components/Footer/FooterBottom";
import FooterTop from "../components/Footer/FooterTop";
// import FooterMiddle from "../components/Footer/FooterMiddle";
import UpcommingCardList from "../components/UpcommingCards/UpcommingCardList";
import PartnersWeb3Economy from "../components/PartnersWeb3Economy/PartnersWeb3Economy";
import Product from "../components/Product/Product";
import HeaderSection from "../components/HeaderSection/HeaderSection";
import Social from "../components/SocialComp/Social";
import TotalProjects from "../components/TotalProjects/TotalProjects";
import BacktoTopDark from "../assets/BacktoTopDark.svg";
import BacktoTopLight from "../assets/BacktoTopLight.svg";
import BacktoTopPNG from "../assets/BacktoTopPNG.png";
import { useTheme } from "styled-components";
import { getBackendUrl } from "../api/constant";
// import FotterBottomLinks from "../components/Footer/FotterBottomLinks/FotterBottomLinks";
const Wrapper = styled.div`
  //margin: 0 5rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BackToTop = styled.img`
  position: fixed;
  bottom: 9rem;
  right: 0.6rem;

  transition-duration: 0.3s;
  z-index: 10;
  cursor: pointer;
  opacity: ${(props) => props.visibility};
`;

function HomePage() {
  const [endedProjects, setEndedProjects] = useState([]);
  const [scrollValue, setScrollValue] = useState();
  const [pageNum, setPageNum] = useState(1);
  const theme = useTheme();
  const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

  useEffect(() => {
    fetchEndedProjects(pageNum);
  }, [pageNum]);

  async function fetchEndedProjects(pageNo) {
    try {
      await fetch(`${apiUrl}v2/projects/ended?pageNo=${pageNo}`)
        .then((response) => response.json())
        .then((res) => {
          // console.log("res", res.data);
          setEndedProjects([...endedProjects, ...res.data]);
        });
    } catch (e) {
      console.log(e);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", () => {
    let scrolled = window.scrollY;

    setScrollValue(scrolled);
  });

  return (
    <>
      <Navbar />

      <BackToTop
        src={theme.mode === "dark" ? BacktoTopDark : BacktoTopPNG}
        onClick={scrollToTop}
        visibility={scrollValue !== 0 ? "1" : "0"}
      />

      <Wrapper>
        <HeaderSection />
        <Social />
        <FeaturedProjects />
        <UpcommingCardList />
        <TotalProjects />
        {endedProjects.length > 0 && (
          <EndedProjects
            endedProjects={endedProjects}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        )}

        <Product />
        <AsvaTokenUtility />
        <PartnersWeb3Economy />
        <FooterTop />
        {/* <FooterMiddle /> */}
      </Wrapper>
      <FooterBottom />
      <Wrapper></Wrapper>
    </>
  );
}

export default HomePage;
