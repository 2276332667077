import styled from "styled-components";
import Slider from "react-slick";
import Carousel from "react-elastic-carousel";
export const SliderStyles = styled(Slider)`
  max-width: 1500px;
  & .slick-arrow slick-prev {
    color: black;
  }
  & .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.grey["100"]
        : props.theme.colors.grey["300"]};
  }
  & .slick-dots li.slick-active button {
    transition: 0.5s;
    width: 20px;
    height: 8px;
    border-radius: 10px;
    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
  }
  & .slick-dots li button:before {
    display: none;
  }
`;
export const CarouselStyles = styled(Carousel)`
  & .rec-wrapper-carousel {
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
      display: ${(props) => props.length && "none"};
    }
  }
  & .rec-pagination {
    margin-right: auto;
    margin-left: 20px;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
    @media (min-width: 300px) {
      display: ${(props) => props.Mobilelength && "none"};
    }
  }
  & .rec-arrow-left {
    // position: absolute;
    // left: 1020px;
  }
  & .rec-arrow {
    display: none;
    background-color: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.black["400"]
        : "#F4F5F9"};
    min-width: 0px;
    width: 0px;
    box-shadow: 0 0;
    border: 1px solid
      ${(props) =>
        props.theme.mode === "dark"
          ? props.theme.colors.black["400"]
          : "#F4F5F9"};
    margin-top: 12rem;

    &:hover {
      background-color: ${(props) =>
        props.theme.mode === "dark"
          ? props.theme.colors.black["400"]
          : "#F4F5F9"};
      box-shadow: 0 0;
      color: ${(props) => (props.theme.mode === "dark" ? "white" : "black")};
    }
  }
  // & .rec-arrow-left {
  //   position: absolute;
  //   left: 28px;
  // }
  // & .rec-arrow-right {
  //   position: absolute;
  //   right: 28px;
  // }

  & .rec-dot {
    border: none;
    width: 8px;
    height: 8px;

    background: #b8c1da;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
    @media (min-width: 300px) {
      display: ${(props) => props.Mobilelength && "none"};
    }
  }
  & .rec-slider-container {
    @media (min-width: 1200px) {
      margin: 0 18px;

      margin: 0 ${(props) => props.Mobilelength && "180px"};
    }
  }
  & .rec-dot_active {
    // width: 30px;
    // padding: 20px 0;
    width: 25px;

    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
    border-radius: 10px;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
    /* Inside auto layout */
    @media (max-width: 1000px) {
      display: ${(props) => props.length && "none"};
    }
  }
`;

export const CardContainer = styled.div`
  /* flex: 1 0 25%; */
  /* margin: 15px; */
  /* max-width: 380px; */
  /* background: #191c27; */
  width: 360px;
  margin: 0 14px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: ${(props) => props.length && "none"};
  }
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["300"]
      : props.theme.colors.grey["100"]};
  border-radius: 10px;
  transition: 0.6s;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 320px;
  }
  &:hover {
    transform: translate(0, -7px);
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.5em;
  }
  .details-container {
    position: relative;
    padding: 0 24px;
    padding-top: 10px;
  }
`;

export const CardImgContainer = styled.div`
  margin-bottom: 5%;
  position: relative;
  border-radius: 5px 5px 0 0;
`;
export const BannerImg = styled.img`
  /* max-width: auto; */
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0 0;
`;
export const NameContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ProjectName = styled.p`
  margin: 0;
  font-weight: 500;
  /* font-size: 1.2em; */
  font-size: 1.25rem;
  line-height: 130%;
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const TokenName = styled.p`
  margin: 0;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  margin-left: 0.5em;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
    color: #777d92;
  }
`;

export const SupportedTokens = styled.div`
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  /* font-size: 12px; */
  /* line-height: 150%; */
  display: flex;
  align-items: center;
  text-align: center;
  color: #777d92;
  border-left: 1px solid #31374d;
  padding-left: 5px;
  font-size: 8px;
`;

export const PipeDivider = styled.p`
  margin: 0 0.2em;
  color: #31374d;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0.3rem;
  }
`;

export const ProjectType = styled.div`
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  padding: 4px;
  position: absolute;
  /* width: 50px;
    height: 24px; */
  right: 8px;
  top: 8px;
  /* Yellow/Yellow_400 */
  color: #000;
  background: ${(props) => {
    if (props.type === "Public IDO") {
      return props.theme.colors.yellow[400];
    }
    if (props.type === "Community IDO") {
      return props.theme.colors.green[400];
    }
    return props.theme.colors.yellow[400];
  }};
  border-radius: 4px;
`;

export const CardLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  left: 20px;
  width: 48px;
  height: 48px;
  top: -40px;
  /* right: 81.05%; */
  /* top: 150px; */
  /* bottom: 48.46%; */
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["300"]
      : props.theme.colors.grey["100"]};
  border-radius: 50%;
  img {
    max-width: 100%;
  }
`;
export const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  margin-right: auto;
  letter-spacing: -1px;
  margin-top: 3rem;
  margin-bottom: 1.9rem;
  /* V2/Light/100 */

  color: ${(props) => (props.theme.mode === "dark" ? "#ffffff" : "#090A0E")};
  text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1.9rem auto;
  }
`;

export const CardDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  /* padding: 5%; */
`;
export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1% 5%; */
  margin-bottom: 12px;
`;

export const RowText = styled.p`
  margin: 0;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 145%; */
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const RowTextSpan = styled.span`
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  color: #3beeadcc;
  margin-left: 5px;
`;

export const WhiteListStatus = styled.div`
  padding: 6px;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  font-size: 0.8em;
`;

export const NetworkInfo = styled.div`
  /* width: 62px;
  height: 26px; */
  /* background: #12141c; */
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["400"]
      : props.theme.colors.grey["200"]};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

export const Divider = styled.hr`
  /* border: 1.5px solid #12141c; */
  border: 1px solid
    ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.black["400"]
        : `${props.theme.colors.grey["200"]}99`};
  margin: 5% 1.05%;
  margin-bottom: 2%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 9% 1.05%;
    margin-bottom: 2%;
  }
`;

export const DashedDivider = styled.hr`
  flex: 1;
  margin: 0 20px;
  border: none;
  height: 2px;
  background: #000;
  background: repeating-linear-gradient(
    90deg,
    #31374d,
    #31374d 6px,
    transparent 6px,
    transparent 12px
  );
`;
//Custom Pagination
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 600px) {
    display: ${(props) => props.length && "none"};
  }
  @media (min-width: 300px) {
    display: ${(props) => props.Mobilelength && "none"};
  }
`;
export const Pagination = styled.div`
  display: flex;
  gap: 12px;
`;
export const PaginationDots = styled.div`
  width: 8px;
  height: ${(props) => (props.active ? "19px" : "8px")};

  
  background:${(props) =>
    props.active
      ? "linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%)"
      : "rgba(255, 255, 255, 0.13)"}
  border-radius: 50%;
  
  transform: rotate(90deg);
  border: 1px solid black;
  /* Inside auto layout */
  padding: 1px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ArrowContainer = styled.div`
  display: flex;
  gap: 10px;

  right: 0;

  @media (max-width: 875px) {
    position: absolute;
    right: 0;
    top: 364px;
  }
  @media (max-width: 629px) {
    position: absolute;
    right: 0;
    top: 350px;
  }
  @media (max-width: 487px) {
    position: absolute;
    right: 0;
    top: 375px;
  }
  @media (max-width: 367px) {
    position: absolute;
    right: 0;
    top: 367px;
  }
`;
export const ArrowLeft = styled.img`
    
}`;
export const ArrowRight = styled.img``;
export const Number = styled.p`
  margin-top: 13px;
  color: ${(props) => props.theme.mode === "dark" && "#FFFFFF"};
`;
export const Hr = styled.hr`
  width: 84%;
  margin: -18px auto 0px auto;
  height: 0px;

  /* Light/Light_200 */

 height:0.1px;
border: 0.1px solid ${(props) =>
  props.theme.mode === "dark" ? "#777d92" : "#e7eaf3"}
  /* Inside auto layout */
background:${(props) => (props.theme.mode === "dark" ? "#777d92" : "#e7eaf3")};
  flex: none;
  opacity:0.1;
  order: 4;
  flex-grow: 0;
  @media (max-width: 971px) {
    width:80%;
  }
  @media (max-width: 597px) {
    width:65%;
    margin-left:105px
  }
  
  @media (max-width: 451px) {
    width:45%;
    margin-left:110px
  }
  // @media (max-width: 367px) {
  //   position: absolute;
  //   right: 0;
  //   top: 367px;
  // }
`;
export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;

  @media (min-width: 1200px) {
    display: ${(props) => props.disable && "none"};
  }
  @media (max-width: 800px) {
    display: ${(props) => props.show && "none"};
  }
`;

export const CustomDot = styled.div`
  width: ${(props) => (props.active ? "30px" : "8px")};
  height: 8px;
  margin: 0 0.2rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  background: ${(props) =>
    props.active
      ? "linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%)"
      : props.theme.mode === "dark"
      ? "rgba(255, 255, 255, 0.13)"
      : "#B8C1DA"};
  box-shadow: ${(props) =>
    props.active && "-1px 2px 5px rgba(179, 39, 155, 0.54)"};
  border-radius: 10px;
`;

export const PaginationDivider = styled(Divider)`
  height: 0.1px;
  flex: 1;
  margin: 0 10px;
  margin-top: 2px;
  border: ${(props) =>
    props.theme.mode === "dark"
      ? "1px solid rgba(255, 255, 255, 0.1)"
      : " 1px solid #E7EAF3"};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: -8px;
`;

export const SlideNumsWrapper = styled.div`
  text-align: center;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["400"]};
  display: contents;
`;

export const SlideNum = styled.p`
  margin: 0 0.3rem;
  font-weight: 600;
  font-size: 16px;
  opacity: ${(props) => props.opacity};
`;
