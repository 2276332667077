import styled from "styled-components";

export const Container = styled.div`
  // width: 100%;
  height: auto;

  @media (max-width: 782px) {
    margin: 0 1.5rem;
  }
`;

export const ContainerHeading = styled.h1`
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 35px;
  line-height: 47px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -1px;

  /* V2/Primary/500 */

  color: #ff0074;
  text-transform: capitalize;

  /* grad */

  background: linear-gradient(94.18deg, #ff0074 0%, #6038f7 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 856px) {
    font-size: 25px;
  }
  @media (max-width: 782px) {
    font-size: 18px;
    margin-bottom: 0.1rem;
  }
`;
export const ContainerPara = styled.p`
  max-width: 70%;
  font-style: normal;
  margin-bottom: 2.1rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 145%;
  /* or 29px */

  display: flex;
  align-items: center;

  /* V2/Dark/100 */

  color: #777d92;
  @media (max-width: 856px) {
    font-size: 15px;
  }
  @media (max-width: 782px) {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 2rem;
  }
`;
export const ContainerHoverCircleDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
`;
export const ContainerHoverCircles = styled.div`
  border: 1px solid
    ${(props) => (props.theme.mode === "dark" ? "#777d92" : "#B8C1DA")};
  border-radius: 50%;
  padding: 30px;
  text-align: center;
  width: 200px;
  height: 200px;
  margin: auto;

  @media (max-width: 782px) {
    width: 150px;
    height: 150px;
    // padding: 12%;
    // margin-left: 0.3rem;
  }
`;
export const ContainerHoverCircle = styled.div`
  // max-width: 10rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ContainerHoverCircleImg = styled.img`
  // position: absolute;
  // top: -1.5rem;
  // left: -1.5rem;
  // right: -5rem;

  width: 65px;
  margin-top: ${(props) => (props.type === "discount" ? "4rem" : "2rem")};
  opacity: ${(props) => props.active && 0};
  opacity: 1;
  transition: width 0s, margin 0.5s linear;
  ${ContainerHoverCircles}:hover & {
    margin-top: 0;
    width: 50px;
  }
  transition-duration: 0.6s;
  //media queries for tablet view
  @media (max-width: 876px) {
    // position: absolute;
    // top: -1.5rem;
    // left: -1.7rem;
    // right: -3rem;
    margin-top: 3rem;
  }
  //media queries for mobile view
  @media (max-width: 565px) {
    // position: absolute;
    // top: -0.8rem;
    // left: -0.8rem;
    // right: -3rem;
    // width: 22px;
    margin-top: 2rem;
  }
`;

export const ContainerHoverCircleContent = styled.p`
  // position: absolute;
  // top: -1.5rem;
  // left: -5rem;
  // right: -5rem;
  // font-style: normal;

  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  /* or 23px */

  // display: flex;
  // align-items: center;
  // text-align: center;
  // letter-spacing: -1px;

  // display: none;

  // transition-duration: 0.6s;
  /* V2/Light/100 */
  visibility: hidden;
  // opacity: ${(props) => props.inActive && 0};
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-out;
  ${ContainerHoverCircles}:hover & {
    opacity: 1;
    visibility: visible;
  }
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["100"]};
  @media (max-width: 1021px) {
    font-size: 0.8rem;
    // position: absolute;
    // top: -1.5rem;
    // left: -3rem;
    // right: -3rem;
  }
  @media (max-width: 550px) {
    font-size: 0.8rem;
    // position: absolute;
    // top: -1rem;
    // left: -1.5rem;
    // right: -1.4rem;
  }
`;
export const ContainerHoverCircleContentt = styled.div`
  position: absolute;
  top: -1rem;
  left: -3.6rem;
  right: -5rem;
  font-style: normal;

  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  /* or 23px */
  transition-duration: 0.6s;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;

  /* V2/Light/100 */

  opacity: 0;
  ${ContainerHoverCircles}:hover & {
    opacity: 1;
  }
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["100"]};
  @media (max-width: 1021px) {
    font-size: 0.8rem;
    position: absolute;
    top: -1rem;
    left: -2.7rem;
    right: -3rem;
  }
  @media (max-width: 550px) {
    font-size: 0.5rem;
    position: absolute;
    top: -0.5rem;
    left: -1.5rem;
    right: -1.4rem;
  }
`;
