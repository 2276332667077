import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationContainer,
  Imgtag,
  NavToggleMobileBtnDiv,
  ModalStyles,
  NavContainerModal,
  NavCloseIcon,
} from './Navbar.styles';
import x from './assets/x.svg';
import xDark from './assets/x-dark.svg';
import { useTheme } from 'styled-components';
import { toggleTheme } from '../../store/slices/theme';
import AsvaLabsDark from './assets/AsvaLabsDark.svg';
import AsvaLabsLight from './assets/AsvaLabsLight.svg';
import HoverImageDark from './assets/HoverImageDark.svg';
import HoverImageLight from './assets/HoverImageLight.svg';
import ThemeChangeLight from './assets/ThemeChangeLight.svg';
import ThemeChangeDark from './assets/ThemeChangeDark.svg';
import MobileButtonLight from './assets/mobile-button-light.svg';
import MobileButtonDark from './assets/mobile-button-dark.svg';
import CloseButton from './assets/CloseButton.svg';
import MetaLaunch from '../../assets/Metalaunchlogo.png';
import ApplyNowSVG from './assets/ApplyNowSVG.svg';
import {
  NavTextContainer,
  NavLI,
  NavContainer,
  NavImageContainer,
  NavUL,
  NavButton,
  NavP,
  NavToggleBtn,
  NavImage,
  NavWrapperDiv,
  NavDivContainer,
  NavDiv,
  NavMobileButton,
  NavToggleBtnDiv,
  NavBtnDivConnect,
  NavHoverDiv,
} from './Navbar.styles';

function Navbar() {
  const [close, setClose] = useState(true);
  const [currentTab, setCurrentTab] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {close && (
        <NotificationContainer type="old-link">
          <p className="text" type="old-link">
            Metalaunch is rebranding to Superportal.
            <a
              className="old-link-a"
              target="_blank"
              href="https://superportal.ai/"
              rel="noreferrer"
            >
              Visit Now!
            </a>
          </p>
          <Imgtag
            src={x}
            alt=""
            onClick={() => {
              setClose(false);
            }}
          />
        </NotificationContainer>
      )}
      <NavContainer>
        <NavToggleMobileBtnDiv
          button
          type='button'
          onClick={() => {
            dispatch(toggleTheme());
          }}
        >
          <NavToggleBtn>
            <NavImage color='#FFFFFF'>
              <img
                src={theme.mode === 'dark' ? ThemeChangeDark : ThemeChangeLight}
                alt='ChangeTheme'
              />
            </NavImage>
          </NavToggleBtn>
        </NavToggleMobileBtnDiv>

        <NavWrapperDiv>
          <NavImageContainer>
            <img
              src={MetaLaunch}
              alt='Asva Labs'
              style={{ width: '100%', height: '50%' }}
            />
          </NavImageContainer>
          {/* <NavTextContainer>By AsvaLabs</NavTextContainer> */}
        </NavWrapperDiv>
        <NavDivContainer>
          <NavUL>
            {/* <a
              className="no-color"
              target="_blank"
              href="http://incubation.metalaunch.io/"
              rel="noreferrer"
            >
              <NavLI
                onClick={() => setCurrentTab("Home")}
                borderImage={
                  currentTab === "Home"
                    ? "linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)"
                    : "#12141C"
                }
                active={currentTab === "Home"}
                onMouseEnter={(e) => {
                  setShow3(true);
                }}
                onMouseLeave={(e) => {
                  setShow3(false);
                }}
              >
                Incubation
                {show3 && (
                  <NavHoverDiv>
                    <img
                      src={ApplyNowSVG}
                      alt="HoverImage"
                      width="100%"
                      height="100%"
                    />
                  </NavHoverDiv>
                )}
              </NavLI>
            </a> */}
            <a href='https://app.metalaunch.io/' target='_blank'>
              <NavLI
                onClick={() => setCurrentTab('Stake/Farm')}
                borderImage={
                  currentTab === 'Stake/Farm'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Stake/Farm'}
                onMouseEnter={(e) => {
                  setShow(true);
                }}
                onMouseLeave={(e) => {
                  setShow(false);
                }}
              >
                IDO Launchpad
                {/* {show && (
                  <NavHoverDiv>
                    <img
                      src={
                        theme.mode === 'dark' ? HoverImageDark : HoverImageLight
                      }
                      alt='HoverImage'
                      width='80px'
                    />
                  </NavHoverDiv>
                )} */}
              </NavLI>
            </a>
            <a href='https://asvarium.com/' target='_blank'>
              <NavLI
                onClick={() => setCurrentTab('Metaverse Marketplace')}
                borderImage={
                  currentTab === 'Metaverse Marketplace'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Metaverse Marketplace'}
              >
                NFT Launchpad
              </NavLI>
            </a>
            <NavLI
              onClick={() => setCurrentTab('Community')}
              borderImage={
                currentTab === 'Community'
                  ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                  : '#12141C'
              }
              active={currentTab === 'Community'}
              onMouseEnter={(e) => {
                setShow1(true);
              }}
              onMouseLeave={(e) => {
                setShow1(false);
              }}
            >
              DAO Governance
              {''}
              {show1 && (
                <NavHoverDiv>
                  <img
                    src={
                      theme.mode === 'dark' ? HoverImageDark : HoverImageLight
                    }
                    alt='HoverImage'
                    width='80px'
                  />
                </NavHoverDiv>
              )}
            </NavLI>
          </NavUL>
        </NavDivContainer>

        <NavDiv>
          <a target='_blank' href='https://app.metalaunch.io/' rel='noreferrer'>
            <NavBtnDivConnect>
              <NavButton>
                <NavP>Launch App</NavP>
              </NavButton>
            </NavBtnDivConnect>
          </a>
          <NavToggleBtnDiv
            button
            type='button'
            onClick={() => {
              dispatch(toggleTheme());
            }}
          >
            <NavToggleBtn>
              <NavImage color='#FFFFFF'>
                <img
                  src={
                    theme.mode === 'dark' ? ThemeChangeDark : ThemeChangeLight
                  }
                  alt='ChangeTheme'
                />
              </NavImage>
            </NavToggleBtn>
          </NavToggleBtnDiv>
          <NavMobileButton onClick={() => showModal()}>
            <img
              src={theme.mode === 'dark' ? MobileButtonDark : MobileButtonLight}
              alt='mobile-button'
            />
          </NavMobileButton>
        </NavDiv>

        <ModalStyles
          footer={null}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={400}
          height={340}
        >
          <NavContainerModal>
            <NavCloseIcon src={CloseButton} onClick={handleCancel} />
            <NavUL>
              <a
                className='no-color'
                target='_blank'
                href='https://docs.google.com/forms/d/1CY0eZEhRjapUzMPRGfRVOzbhayA-3NfAhqXmoNBIcvU/viewform?edit_requested=true'
                rel='noreferrer'
              >
                <NavLI
                  onClick={() => setCurrentTab('Incubation')}
                  borderImage={
                    currentTab === 'Incubation'
                      ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                      : '#12141C'
                  }
                  active={currentTab === 'Incubation'}
                >
                  Incubation
                </NavLI>
              </a>
              <a
                className='no-color'
                target='_blank'
                href='https://app.metalaunch.io/'
                rel='noreferrer'
              >
                <NavLI
                  onClick={() => setCurrentTab('DAO Governance')}
                  borderImage={
                    currentTab === 'DAO Governance'
                      ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                      : '#12141C'
                  }
                  active={currentTab === 'DAO Governance'}
                >
                  IDO Launchpad
                </NavLI>
              </a>
              <a
                className='no-color'
                target='_blank'
                href='https://asvarium.com/'
                rel='noreferrer'
              >
                <NavLI
                  onClick={() => setCurrentTab('Metaverse Marketplace')}
                  borderImage={
                    currentTab === 'Metaverse Marketplace'
                      ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                      : '#12141C'
                  }
                  active={currentTab === 'Metaverse Marketplace'}
                >
                  NFT Launchpad
                </NavLI>
              </a>
              <NavLI
                onClick={() => setCurrentTab('Community')}
                borderImage={
                  currentTab === 'Community'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Community'}
              >
                DAO Governance
              </NavLI>
            </NavUL>
          </NavContainerModal>
        </ModalStyles>
      </NavContainer>
    </>
  );
}

export default Navbar;
