import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyles = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
  /* @media (max-width: 767px) {
    &.ant-modal {
      margin: 0 0 0 7px;
    }
  } */
  /* &.ant-modal {
    margin: 0 0 0 7px;
  } */
  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['100']};
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 50px 0 50px 0;
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  /* overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%; */
  /* position: sticky;
  top: 0; */

  background-color: ${(props) => {
    if (props.type === 'old-link') {
      return props.theme.mode === 'dark' ? '#ff0074' : '#ff0074';
    }

    return props.theme.mode === 'dark'
      ? 'rgba(252, 183, 120, 0.2)'
      : props.theme.colors.yellow[500];
  }};

  .text {
    text-align: center;
    font-size: 16px;
    margin: 0;
    color: ${(props) => {
    if (props.type === 'old-link') {
      return props.theme.mode === 'dark' ? 'white' : 'white';
    }
  }};

    .old-link-a {
      padding-left: 7px;
      text-decoration: underline;
      text-decoration-thickness: from-font;
      text-decoration-color: white;
      color: ${(props) =>
    props.theme.mode === 'dark' ? 'white' : 'white'};
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    // display: none;
  }
`;

export const NotificationText = styled.p``;

export const Imgtag = styled.img`
  cursor: pointer;
  margin-left: 100px;
`;

export const NavContainer = styled.div`
  /* display: flex; */
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid
    ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};

  overflow: hidden;
  /* position: fixed;
  top: 0;
  width: 100%; */
  position: sticky;
  top: 0;
  z-index: 1000;
  @media screen and (max-width: 1290px) {
    padding-right: 0px !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    overflow: hidden;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 130%; */
    flex-direction: row;
    overflow: hidden;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['100']};
`;

export const NavRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* margin: 10px 0px 10px auto; */
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px 0px 10px 0;
  }
`;

export const NavMobileButton = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: inline;
    /* margin-left: 10px; */
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: inline;
    /* margin-left: 10px; */
  }
`;

export const NavWrapperDiv = styled.div`
  /* padding: 0px 21px 0px 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* &:before {
    border-right: 1.5px solid
      ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
    content: '';
    position: absolute;
    right: 0;
    top: -15px;
    z-index: 100;
    width: 0px;
    height: 165%;

    background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  } */
  @media screen and (max-width: 1290px) {
    padding: 0px 21px 0px 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px 21px 0px 0px;
    &:before {
      border: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px 0px 0px 0px;
    width: 292px;

    &:before {
      border: 0;
    }
  }
`;

export const NavImageContainer = styled.div`
  display: block;
  width: 178px;
  padding-left: 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* max-width: 15px;
    max-height: 20px; */
  }
`;
export const NavHoverDiv = styled.div`
  margin-top: 7px;
  width: 100%;
`;

export const NavTextContainer = styled.h1`
  /* font-family: ${(props) => props.theme.font.family.primary}; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};
  margin-bottom: 0px;
  padding-top: 1px;
`;
export const NavDivContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const NavUL = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  margin: 0;
  /* padding: 0 0 0 60px; */
  position: relative;

  .no-color {
    color: inherit;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* padding: 0 0 0 60px; */
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;

export const NavLI = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 33px 0px 33px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  display: block;
  cursor: pointer;

  border-bottom: 4px solid transparent;
  border-image: ${(props) => props.borderImage};
  border-image-slice: 1;
  height: 80px;
  border-radius: 15px 15px 0px 0px;
  &:hover {
    /* color: #b8c1da; */
    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
  }
  /* color: ${(props) => props.color}; */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  /* &:active {
    color: #ffffff;
    cursor: pointer;
    gap: 30px;
    padding: 33px 0px 33px 0px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      padding: 0 0 3px 0;
      background: linear-gradient(118.21deg, #ec4781, #6038f7 130.15%);
      box-shadow: -6px 2px 13px rgba(179, 39, 155, 0.54);
      border-radius: 15px 15px 0px 0px;
    }
  } */
  /* &.active {
    color: #ffb7e2;
    text-shadow: 0px 4px 7px #ff2d78;
    height: 10px;
  } */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};
  }
`;

export const AncorTag = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 33px 0px 33px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  display: block;
  cursor: pointer;

  border-bottom: 4px solid transparent;
  border-image: ${(props) => props.borderImage};
  border-image-slice: 1;
  height: 80px;
  border-radius: 15px 15px 0px 0px;
  &:hover {
    /* color: #b8c1da; */
    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
  }
  /* color: ${(props) => props.color}; */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  /* &:active {
    color: #ffffff;
    cursor: pointer;
    gap: 30px;
    padding: 33px 0px 33px 0px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      padding: 0 0 3px 0;
      background: linear-gradient(118.21deg, #ec4781, #6038f7 130.15%);
      box-shadow: -6px 2px 13px rgba(179, 39, 155, 0.54);
      border-radius: 15px 15px 0px 0px;
    }
  } */
  /* &.active {
    color: #ffb7e2;
    text-shadow: 0px 4px 7px #ff2d78;
    height: 10px;
  } */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};
  }
`;
export const NavDiv = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-evenly;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* margin-left: auto; */
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-left: 0;
  }
`;

export const NavBtnDivConnect = styled.div`
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  /* padding: 0 12px 0 0; */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
    padding: 0;
    margin: 0;
  }
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  padding: 6px 16px;
  outline: none;
  border: none;
  height: 48px;

  background: #ff0074;
  border-radius: 6px;
`;

export const NavP = styled.p`
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;

  color: #e7eaf3;

  line-height: 20px;
  color: #ffffff;
  margin: 0;
`;

export const NavSpan = styled.span`
  color: ${(props) => props.color};
  /* color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['200']}; */
  font-weight: 500;
  padding: 1px 0 2px 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const NavToggleBtnDiv = styled.div`
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin-right: 10px; */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* padding: 0;
    margin-right: 10px; */
    display: none;
  }
`;
export const NavToggleMobileBtnDiv = styled.div`
  /* background: #191c27; */
  /* background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center; */
  /* margin-right: 10px; */
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
    background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
    border-radius: 6px;
    align-items: center;
    padding: 0;
    /* margin-right: 10px; */
  }
`;

export const NavToggleBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;

  border: none;
  outline: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }
`;

export const NavImage = styled.image`
  color: ${(props) => props.color};
`;

export const NavContainerModal = styled.div`
  padding-top: 30px;
  border-radius: 20px;
  position: relative;
`;

export const NavCloseIcon = styled.img`
  position: absolute;
  top: -12%;
  /* left: 15px; */
  right: 15px;
  z-index: 100;
  cursor: pointer;
`;
