import React, { useState } from 'react';
import { useRef } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import {
  CardImgContainer,
  CardLogo,
  CardWrapper,
  DashedDivider,
  Divider,
  EndedCarousel,
  ExpandedCard,
  ItemRow,
  NameContainer,
  NetworkInfo,
  ProjectName,
  RowText,
  SaleText,
  SupportedTokens,
  DateText, 
  TimerContainer,
  TitleRow,
  TokenName,
  AthText,
  FlexContainer,
  CustomDot,
  Icons,
  PaginationDivider,
  ButtonsContainer,
  SlideNumsWrapper,
  SlideNum,
  ProjectType,
  VerticalCardInfo,
  VerticalCardLogo,
  Logo,
} from './EndedProjects.style';
import arrowDark from '..//../assets/arrowDark.svg';
import ForwardArrowDark from '..//../assets/ForwardArrowDark.svg';
import arrowDarkRight from '..//../assets/arrowDarkRight.svg';
import arrowLightLeft from '..//../assets/arrowLightLeft.svg';
import arrowRight from "./assets/arrowRight.svg";
import arrowLeft from "./assets/arrowLeft.svg";
import { Heading } from '../FeaturedProjects/FeaturedProjects.style';
import { useTheme } from 'styled-components';
import NetworkImg from '../NetworkImg/NetworkImg';
import moment from 'moment';

function EndedProjects({ endedProjects, pageNum, setPageNum }) {
  const carouselRef = useRef();
  const theme = useTheme();

  const [index, setIndex] = useState(0);
 
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
  ];

  const gotoPrevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
    carouselRef.current.slidePrev();
  };

  const gotoNextSlide = () => {
    carouselRef.current.slideNext();

    if (index < carouselRef.current.getNumOfPages() - 1) {
      setIndex(index + 1);
    }
  };
  // function myArrow( type, onClick, isEdge ) {
  //     const pointer = type === consts.PREV ? arrowLeft : arrowRight;
  //     return (
  //       <button onClick={onClick} disabled={isEdge} style={{margin:0, padding:0, border:"0px",width:"50px",
  //       backgroundColor:"transparent"}}>
  //         <Icons src={pointer} onClick={onClick}/>
  //       </button>
  //     )
  //   }

  
  return (
    <>
      <Heading>Ended Projects</Heading>
      <EndedCarousel
        ref={carouselRef}
        breakPoints={breakPoints}
        showArrow={true}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <FlexContainer>
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  <CustomDot
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                  />
                );
              })}
              <PaginationDivider />
              
            </FlexContainer>
          );
        }}
        onChange={(currentItemObject, currentPageIndex) => {
          if (index === carouselRef.current.getNumOfPages() - 1) {
            setPageNum(pageNum + 1);
          }
        }}
      >
        {endedProjects.map((item) => (
          <CardWrapper key={item._id} bgImage={item.projectBannerUrl}>
            <a
              href={`https://app.metalaunch.io/pools/${item._id}`}
              target='_blank'
            >
              {/* <h1>1</h1> */}
              {/* <ProjectType type={item.typeOfProject}>
              {item.typeOfProject}
            </ProjectType>
            <VerticalCardLogo>
              <Logo src={item.projectLogoUrl} alt="" />
            </VerticalCardLogo>
            <VerticalCardInfo>
              <NameContainer>
                <ProjectName>{item.projectName}</ProjectName>
                <TokenName>Token Name</TokenName>
              </NameContainer>
              <NetworkInfo>
                <NetworkImg
                  networkId={item.projectNetwork.networkId}
                  networkName={item.projectNetwork.networkName}
                />
                <SupportedTokens>BUSD</SupportedTokens>
              </NetworkInfo>
            </VerticalCardInfo> */}
              <ExpandedCard style={{borderRadius:"1rem"}}>
                <CardImgContainer bgImage={item.projectBannerUrl} >
                  <ProjectType type={item.typeOfProject} style={{marginRight:"0.5rem"}}>
                    {item.typeOfProject}
                  </ProjectType>
                </CardImgContainer>
                <div className='details-container'>
                  <TitleRow>
                    <NameContainer>
                      <CardLogo>
                        <Logo src={item.projectLogoUrl} alt='' />
                      </CardLogo>
                      <ProjectName>{item.projectName}</ProjectName>
                      <TokenName>Token Name</TokenName>
                    </NameContainer>
                    <NetworkInfo>
                      <NetworkImg
                        networkId={item.projectNetwork.networkId}
                        networkName={item.projectNetwork.networkName}
                      />
                      <SupportedTokens>BUSD</SupportedTokens>
                    </NetworkInfo>
                  </TitleRow>

                  <ItemRow>
                    <RowText>Total Raise</RowText>
                    <DashedDivider />
                    <RowText>{item.tokenDetails.totalFundraise}</RowText>
                  </ItemRow>
                   <ItemRow>
                    <RowText>All Time High</RowText>
                    <DashedDivider />
                    {(item?.coingecko?.ath === undefined || item?.coingecko?.ath === 0 || item?.coingecko?.ath === null  )  && <AthText >- -</AthText>}
                    {Math.sign(item?.coingecko?.ath ) === 1 && <AthText>+{item?.coingecko?.ath}%</AthText>}
                    {Math.sign(item?.coingecko?.ath ) === -1 && <AthText>{item?.coingecko?.ath}%</AthText>}
                  </ItemRow>
                  <Divider />
                  <TimerContainer>
                    <SaleText>Sale Ended</SaleText>
                    <DateText>{`${moment
                      .utc(item.idoDates.roundTwoEndDate)
                      .format('Do MMM YYYY HH:mm A')}`}</DateText>
                  </TimerContainer>
                </div>
              </ExpandedCard>
            </a>
          </CardWrapper>
        ))}
      </EndedCarousel>
    </>
  );
}

export default EndedProjects;
