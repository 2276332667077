import ReactElasticCarousel from "react-elastic-carousel";
import styled from "styled-components";
import bgImage from "../../assets/cardBanner.png";
import { theme } from "../../theme/theme";

export const EndedCarousel = styled(ReactElasticCarousel)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & .rec-arrow {
      display: none;
    }
  }
  & .rec-wrapper-carousel {
    width: 100%;
  }
  & .rec-pagination {
    margin-right: auto;
    margin-left: 20px;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
  }
  & .rec-arrow-left {
    // position: absolute;
    // left: 1020px;
  }
  & .rec-arrow {
    background: #ffffff;
    /* V2/Light/200 */
    border: 1px solid #e7eaf3;
    box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    &:hover {
      background-color: ${(props) =>
        props.theme.mode === "dark"
          ? props.theme.colors.black["400"]
          : "#F4F5F9"};
      box-shadow: 0 0;
      color: ${(props) => (props.theme.mode === "dark" ? "white" : "black")};
    }
  }

  & .sc-breuTD iMjHLv rec rec-arrow rec rec-arrow-right {
    width: 10px !important;
  }
  // & .rec-arrow-right {
  //   position: absolute;
  //   right: 28px;
  // }

  & .rec-dot {
    border: none;
    width: 8px;
    height: 8px;

    background: #b8c1da;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
  }
  & .rec-dot_active {
    // width: 30px;
    // padding: 20px 0;
    width: 25px;

    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
    border-radius: 10px;
    @media (min-width: 600px) {
      display: ${(props) => props.length && "none"};
    }
    /* Inside auto layout */
  }
`;

export const ExpandedCard = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  /* transition: 0.5s ease-in; */
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["300"]
      : props.theme.colors.grey["100"]};
  border-radius: 6px;

  transition: 0.5s;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 320px;
  }
  &:hover {
    transform: translate(0, -7px);
  }
  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.5em;
  }

  .details-container {
    position: relative;
    padding: 0 24px;
    padding-top: 10px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const VerticalCardInfo = styled(TitleRow)`
  margin-left: 20px;
  margin-right: 20px;
`;

export const VerticalCardLogo = styled.div`
  margin-top: 100%;
  margin-left: 20px;
  max-width: 48px;
  max-height: 48px;
`;

export const CardWrapper = styled.div`
  width: 330px;
  height: 420px;
  position: relative;
  // background: url(${(props) => props.bgImage || bgImage}) center no-repeat;
  transition: 0.5s ease;
  overflow: hidden;
  border-radius: 6px;
  // &:hover {
  //   width: 480px;
  //   /* transition: 0.5s ease; */
  //   background: none; //
  //   ${ExpandedCard} {
  //     opacity: 1;
  //     /* transition: 0.5s ease; */
  //   }
  //   ${VerticalCardInfo} {
  //     opacity: 0;
  //   }
  //   ${VerticalCardLogo} {
  //     opacity: 0;
  //   }
  // }
`;

export const CardImgContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  background: url(${(props) => props.bgImage || bgImage}) top no-repeat;
  background-size: cover;
`;

export const ProjectType = styled.div`
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  padding: 4px;

  position: absolute;
  /* width: 50px;
    height: 24px; */
  right: 8px;
  top: 8px;

  /* Yellow/Yellow_400 */

  color: #000;
  background: ${(props) => {
    if (props.type === "Public IDO") {
      return props.theme.colors.yellow[400];
    }
    if (props.type === "Community IDO") {
      return props.theme.colors.green[400];
    }

    return props.theme.colors.yellow[400];
  }};
  border-radius: 4px;
`;

export const CardLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 0;
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["300"]
      : props.theme.colors.grey["100"]};
  border-radius: 50%;

  img {
    max-width: 48px;
    max-height: 48px;
  }
`;

export const Logo = styled.img`
  width: 48px;
  height: 48px;
`;

export const NameContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ProjectName = styled.p`
  margin: 0;
  font-weight: 500;
  /* font-size: 1.2em; */
  font-size: 1.25rem;
  line-height: 130%;
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const TokenName = styled.p`
  margin: 0;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 0.5em;
  margin-left: 0.5em;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
    color: #777d92;
  }
`;

export const SupportedTokens = styled.div`
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  color: #777d92;
  border-left: 1px solid #31374d;
  padding-left: 5px;
  font-size: 12px;
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1% 5%; */
  margin-bottom: 12px;
`;

export const RowText = styled.p`
  margin: 0;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 145%; */
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const AthText = styled(RowText)`
  color: #4fba7a;
`;

export const SaleText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ff0074;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;
export const Icons = styled.img``;
export const WhiteListStatus = styled.div`
  padding: 6px;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  font-size: 0.8em;
`;

export const NetworkInfo = styled.div`
  /* width: 62px;
  height: 26px; */
  /* background: #12141c; */
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["400"]
      : props.theme.colors.grey["200"]};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

export const Divider = styled.hr`
  /* border: 1.5px solid #12141c; */
  border: 1px solid
    ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.black["400"]
        : `${props.theme.colors.grey["200"]}99`};
  margin: 5% 1.05%;
  margin-bottom: 2%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 9% 1.05%;
    margin-bottom: 2%;
  }
`;

export const DashedDivider = styled.hr`
  flex: 1;
  margin: 0 10px;
  border: none;
  height: 2px;
  /* background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["200"]
      : props.theme.colors.grey["300"]}; */
  background: repeating-linear-gradient(
    90deg,
    #31374d,
    #31374d 6px,
    transparent 6px,
    transparent 12px
  );
`;

export const PipeDivider = styled.p`
  margin: 0 0.2em;
  color: #31374d;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0.3rem;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
`;

export const DateText = styled.p`
  margin: 0;
  padding: 4px 6px;
  font-size: 14px;
  background: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.black["300"]
      : props.theme.colors.grey["100"]};
  border: ${(props) =>
    props.theme.mode === "dark"
      ? `1px solid ${props.theme.colors.black["200"]}`
      : `1px solid ${props.theme.colors.grey["100"]}`};

  border-radius: 4px;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["200"]
      : props.theme.colors.black["400"]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
  }
`;

// pagination styles

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
`;

export const CustomDot = styled.div`
  width: ${(props) => (props.active ? "30px" : "8px")};
  height: 8px;
  margin: 0 0.2rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  background: ${(props) =>
    props.active
      ? "linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%)"
      : props.theme.mode === "dark"
      ? "rgba(255, 255, 255, 0.13)"
      : "#B8C1DA"};
  box-shadow: ${(props) =>
    props.active && "-1px 2px 5px rgba(179, 39, 155, 0.54)"};
  border-radius: 10px;
`;

export const PaginationDivider = styled(Divider)`
  height: 0.1px;
  flex: 1;
  margin: 0 10px;
  margin-top: 2px;
  border: ${(props) =>
    props.theme.mode === "dark"
      ? "1px solid rgba(255, 255, 255, 0.1)"
      : " 1px solid #E7EAF3"};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: -8px;
`;

export const SlideNumsWrapper = styled.div`
  text-align: center;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors.grey["100"]
      : props.theme.colors.black["400"]};
  display: contents;
`;

export const SlideNum = styled.p`
  margin: 0 0.3rem;
  font-weight: 600;
  font-size: 16px;
  opacity: ${(props) => props.opacity};
`;
