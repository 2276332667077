import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
// import { modalSlice } from "./slices/modal";
// import { poolSlice } from "./slices/theme";
import { themeSlice } from "./slices/theme";
// import { timeSlice } from "./slices/time";
// import { userSlice } from "./slices/user";
// import { userSlice } from './slices/user';

const rootReducer = combineReducers({
  theme: themeSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
