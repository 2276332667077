import React, { useState } from "react";
import { useTheme } from "styled-components";
import {
  DashedDivider,
  LeftContainer,
  LeftleftContainer,
  LeftrightConatinerImg,
  LeftrightContainer,
  LeftrightContainerImage,
  MainContainer,
  RightContainer,
  RightContainerTextBox,
  RightrightContainer,
  RightrightContainerImage,
  RightrightContainerImg,
} from "./Social.styles";
import avalanche from "./assets/avalanche.svg";
import avalancheLight from "./assets/avalanche-light.svg";
import binance from "./assets/binance.svg";
import polygon from "./assets/polygon.svg";
import polygonLight from "./assets/polygon-light.svg";
import solana from "./assets/solana.svg";
import solanaLight from "./assets/solana-light.svg";
import Discord from "./assets/DARK/DEFAULT/Discord.svg";
import DiscordHover from "./assets/DARK/HOVER/DiscordHover.svg";
import DiscordLight from "./assets/LIGHT/DEFAULT/DiscordLight.svg";
import DiscordHoverLight from "./assets/LIGHT/HOVER/DiscordHoverLight.svg";
import Telegram from "./assets/DARK/DEFAULT/Telegram.svg";
import TelegramHover from "./assets/DARK/HOVER/TelegramHover.svg";
import TelegramLight from "./assets/LIGHT/DEFAULT/TelegramLight.svg";
import TelegramHoverLight from "./assets/LIGHT/HOVER/TelegramHoverLight.svg";
import Twitter from "./assets/DARK/DEFAULT/Twitter.svg";
import TwitterHover from "./assets/DARK/HOVER/TwitterHover.svg";
import TwitterLight from "./assets/LIGHT/DEFAULT/TwitterLight.svg";
import TwitterLightHover from "./assets/LIGHT/HOVER/TwitterHoverLight.svg";
import Youtube from "./assets/DARK/DEFAULT/Youtube.svg";
import YoutubeHover from "./assets/DARK/HOVER/YoutubeHover.svg";
import YoutubeLight from "./assets/LIGHT/DEFAULT/YoutubeLight.svg";
import YoutubeHoverLight from "./assets/LIGHT/HOVER/YoutubeHoverLight.svg";

function Social() {
  const theme = useTheme();
  const [hoverEffect, setHoverEffect] = useState({
    EffectCircle1: false,
    EffectCircle2: false,
    EffectCircle3: false,
    EffectCircle4: false,
  });

  const HoverFunction = (key, val) => {
    const temp = {
      ...hoverEffect,
    };

    temp[key] = val;

    setHoverEffect(temp);
  };
  return (
    <MainContainer>
      <LeftContainer>
        <LeftleftContainer>Raise Capital Across Top Chains.</LeftleftContainer>
        <LeftrightContainer>
          <LeftrightContainerImage>
            <LeftrightConatinerImg src={binance} alt="" />
          </LeftrightContainerImage>
          <LeftrightContainerImage>
            <LeftrightConatinerImg
              src={theme.mode === "dark" ? avalanche : avalancheLight}
              alt=""
            />
          </LeftrightContainerImage>
          <LeftrightContainerImage>
            <LeftrightConatinerImg
              src={theme.mode === "dark" ? solana : solanaLight}
              alt=""
            />
          </LeftrightContainerImage>
          <LeftrightContainerImage>
            <LeftrightConatinerImg
              src={theme.mode === "dark" ? polygon : polygonLight}
              alt=""
            />
          </LeftrightContainerImage>
        </LeftrightContainer>
      </LeftContainer>
      <RightContainer>
        <RightContainerTextBox> Join Our Community </RightContainerTextBox>
        <DashedDivider />
        <RightrightContainer>
          <a
            target="_blank"
            href="https://twitter.com/Metalaunch_io"
            rel="noreferrer"
          >
            <RightrightContainerImage
              onMouseEnter={(e) => {
                HoverFunction("EffectCircle1", true);
              }}
              onMouseLeave={(e) => {
                HoverFunction("EffectCircle1", false);
              }}
            >
              <RightrightContainerImg
                src={
                  theme.mode === "dark"
                    ? hoverEffect.EffectCircle1 === true
                      ? TwitterHover
                      : Twitter
                    : hoverEffect.EffectCircle1 === true
                    ? TwitterLightHover
                    : TwitterLight
                }
                alt=""
              />
            </RightrightContainerImage>
          </a>
          <a
            target="_blank"
            href="https://t.me/AsvaLabsOfficial"
            rel="noreferrer"
          >
            <RightrightContainerImage
              onMouseEnter={(e) => {
                HoverFunction("EffectCircle2", true);
              }}
              onMouseLeave={(e) => {
                HoverFunction("EffectCircle2", false);
              }}
            >
              <RightrightContainerImg
                src={
                  theme.mode === "dark"
                    ? hoverEffect.EffectCircle2 === true
                      ? TelegramHover
                      : Telegram
                    : hoverEffect.EffectCircle2 === true
                    ? TelegramHoverLight
                    : TelegramLight
                }
                alt=""
              />
            </RightrightContainerImage>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UC8QYi6LtKp2URcVpWllBICg"
            rel="noreferrer"
          >
            <RightrightContainerImage
              onMouseEnter={(e) => {
                HoverFunction("EffectCircle3", true);
              }}
              onMouseLeave={(e) => {
                HoverFunction("EffectCircle3", false);
              }}
            >
              <RightrightContainerImg
                src={
                  theme.mode === "dark"
                    ? hoverEffect.EffectCircle3 === true
                      ? YoutubeHover
                      : Youtube
                    : hoverEffect.EffectCircle3 === true
                    ? YoutubeHoverLight
                    : YoutubeLight
                }
                alt=""
              />
            </RightrightContainerImage>
          </a>
          <a
            target="_blank"
            href="https://discord.com/invite/HPTykQyRPY"
            rel="noreferrer"
          >
            <RightrightContainerImage
              onMouseEnter={(e) => {
                HoverFunction("EffectCircle4", true);
              }}
              onMouseLeave={(e) => {
                HoverFunction("EffectCircle4", false);
              }}
            >
              <RightrightContainerImg
                src={
                  theme.mode === "dark"
                    ? hoverEffect.EffectCircle4 === true
                      ? DiscordHover
                      : Discord
                    : hoverEffect.EffectCircle4 === true
                    ? DiscordHoverLight
                    : DiscordLight
                }
                alt=""
              />
            </RightrightContainerImage>
          </a>
        </RightrightContainer>
      </RightContainer>
    </MainContainer>
  );
}

export default Social;
