import React from "react";
import PropTypes from "prop-types";
import bscNetworkImg from "../../assets/testNetworkImg.png";
import avaxNetworkImg from "../../assets/Avalanche.png";
import polygonNetworkImg from "../../assets/Polygon.png";

function NetworkImg({ networkId, networkName }) {
  let renderImg = bscNetworkImg;
  if (networkId === 97 || networkId === 56) {
    renderImg = bscNetworkImg;
  } else if (networkId === 137 || networkId === 80001) {
    renderImg = polygonNetworkImg;
  } else if (networkId === 43114 || networkId === 43113) {
    renderImg = avaxNetworkImg;
  }
  return (
    <img
      src={renderImg}
      alt={networkName}
      style={{
        display: "inline",
        width: "20px",
        height: "20px",
        marginRight: "5px",
      }}
    />
  );
}

NetworkImg.defaultProps = {
  networkName: "",
};
NetworkImg.propTypes = {
  networkId: PropTypes.number.isRequired,
  networkName: PropTypes.string,
};

export default NetworkImg;
