import React from "react";
import { useTheme } from "styled-components";
import {
  MetaLaunchButtomContainer,
  MetaLaunchMiddleContainer,
  MetalaunchTopContainer,
  Ptag,
  Container,
  ContainerInsider,
} from "./FooterTop.styles";
import UpArrow from "./assets/UpArrow.svg";

import MetaLaunchCard from "./MetaLaunchCard";
import FooterMiddle from "./FooterMiddle";
import MetaLaunch2 from "./Metalaunch2";
import MetaLaunch3 from "./Metalaunch3";

function FooterTop() {
  // const theme = useTheme();
  const toggleVisible = () => {
    return document.documentElement.scrollTop;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Container>
      <ContainerInsider>
        <MetalaunchTopContainer>
          Discover More About MetaLaunch
        </MetalaunchTopContainer>
        {/* <MetaLaunchMiddleContainer>
          <Ptag>
            $Asva is Governance and utility token of Asva Labs ecosystem, $ASVA
            holders will get various <br /> benefits from its ecosystem
            applications
          </Ptag>
        </MetaLaunchMiddleContainer> */}
      </ContainerInsider>

      {/* {width >= parseInt(theme.breakpoints.tablet, 10) ? ( */}

      <MetaLaunchButtomContainer>
        <MetaLaunchCard />
        <MetaLaunch2 />
        <MetaLaunch3 />
      </MetaLaunchButtomContainer>

      <FooterMiddle />
      {/* ):()} */}
    </Container>
  );
}

export default FooterTop;
