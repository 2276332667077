import styled from "styled-components";
export const Container = styled.div`
  margin: 0 7rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const MetalaunchTopContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: -1px;
  color: #ff0074;
  margin-top: 6rem;
  margin-bottom: 1.5rem;
  letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 70%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 22px;
    line-height: 26px;
    padding: 0 15px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const MetaLaunchMiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px 0 15px 0;
  }
`;
export const Ptag = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 145%;
  display: flex;
  align-items: center;
  color: #777d92;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* flex: 0.73; */
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
    padding: 0 15px 0 15px;
  }
`;

export const ButtonTag = styled.div`
  background: ${(props) =>
    props.theme.mode === "dark" ? "#31374d" : "#FFFFFF"};
  /* background: #31374d; */
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 168px;
  gap: 16px;

  cursor: pointer;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* flex: 0.1; */
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
export const ButtonTagText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  text-transform: uppercase;
  color: #ff0074;
  min-width: fit-content;
`;
export const MetaLaunchButtomContainer = styled.div`
  display: flex;
  gap: 30px;

  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerInsider = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
