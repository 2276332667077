import React from 'react';
import styled from 'styled-components';
import MetaCardImg3 from './assets/MetaCardImg3.jpeg';

const MetaLaunchContainer = styled.div`
  width: 380px;
  padding-bottom: 3px;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#191c27' : '#FFFFFF'};
  border-radius: 12px;
  transition-duration: 0.5s;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 358px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 343px;
    margin: 0.3rem;
  }
  &:hover {
    transform: translate(0, -7px);
  }
`;

const Image = styled.img`
  max-width: 380px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    height: 197px;
    max-width: 358px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 343px;
    /* height: 0; */
  }
`;
const Wrapper = styled.div`
  margin: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;

const DivText1 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
  color: ${(props) => (props.theme.mode === 'dark' ? '#fafafa' : '#191C27')};
  /* color: #fafafa; */
  margin-bottom: 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;
const DivText2 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 160%;
  text-transform: capitalize;
  color: ${(props) => (props.theme.mode === 'dark' ? '#fafafa' : '#777D92')};
  /* color: #777d92; */
  margin-bottom: 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;
const DivText3 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-transform: capitalize;
  color: #ff0074;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;
function MetaLaunch3() {
  return (
    <a
      href='https://asvalabs.medium.com/metalaunch-v2-a-new-route-to-the-metaverse-a8d6f12c27ea'
      target='_blank'
    >
      <MetaLaunchContainer>
        <Image src={MetaCardImg3} alt='' />
        <Wrapper>
          <DivText1>MetaLaunch v2: A New Route to the Metaverse </DivText1>
          <DivText2>
            The MetaLaunch App is evolving with a completely revitalized UX/UI
            and new features to provide a Pro-User Experience.
          </DivText2>
        </Wrapper>
      </MetaLaunchContainer>
    </a>
  );
}

export default MetaLaunch3;
