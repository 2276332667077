import React from "react";
import {
  Container,
  ContainerLeft,
  ContainerRight,
  HeaderTop,
  Description,
  Buttons,
  ButtonLeft,
  ButtonRight,
  HoverDiv,
  HoverImg1,
  HoverImg2,
  ButtonImg,
  Para,
} from "./HeaderSection.styles";
import "./HeaderSection.css";
import HeaderImageDark from "../../assets/HeaderImageDark.png";
import HeaderImageLight from "../../assets/HeaderImageLight.png";
import HoverButtonArrow from "../../assets/HoverButtonArrow.svg";
import pancakeswap from "../../assets/pancakeswap.svg";
import Mexclogo from "../../assets/Mexclogo.svg";
import { useTheme } from "styled-components";
function HeaderSection() {
  const theme = useTheme();
  return (
    <Container>
      <ContainerLeft>
        <HeaderTop>
          <Para style={{ marginBottom: "0px" }}>
            Backing Web3 Innovations led
          </Para>
        </HeaderTop>
        <HeaderTop>by Visionary Founders</HeaderTop>
        <Description>
          Enabling early access to outlier token deals for the community
        </Description>
        <Buttons>
          <div
            className="HoverMain"
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <ButtonLeft>
              Buy ASVA
              <ButtonImg src={HoverButtonArrow} />
            </ButtonLeft>
            <div className="HoverItem">
              <HoverDiv>
                <HoverImg1>
                  <a
                    href="https://www.mexc.com/exchange/ASVA_USDT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Mexclogo} />
                  </a>
                </HoverImg1>
                <HoverImg2>
                  <a
                    href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xf7b6d7e3434cb9441982f9534e6998c43eef144a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={pancakeswap} />
                  </a>
                </HoverImg2>
              </HoverDiv>
            </div>
          </div>
          <ButtonRight>
            <a
              href="https://stake.metalaunch.io/"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.mode === "dark" ? "white" : "black" }}
            >
              Stake ASVA
            </a>
          </ButtonRight>
        </Buttons>
      </ContainerLeft>
      <ContainerRight>
        {theme.mode === "dark" ? (
          <img src={HeaderImageLight} alt="" style={{ width: "100%" }} />
        ) : (
          <img src={HeaderImageDark} alt="" style={{ width: "100%" }} />
        )}
      </ContainerRight>
    </Container>
  );
}

export default HeaderSection;
