import styled from "styled-components";
import Icon1 from "../../assets/Icon1.svg";
import Logo from "../../assets/Vector.svg";
import Nft from "../../assets/nft.svg";
import Defi from "../../assets/Defi.svg";
import Game from "../../assets/game.svg";
import NftLogo from "../../assets/nftLogo.svg";
import Icon2 from "../../assets/Icon2.svg";
import icon3 from "../../assets/icon3.svg";
import DefiLogos from "../../assets/DefiImg.svg";
import GameLogo from "../../assets/gameLogo.svg";
import gameicon from "../../assets/gameicon.svg";

export const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 900px) {
    justify-content: space-around;
  }
`;
export const CardContainer = styled.div`
  margin: 10px;
  position: relative;
  width: 260px;
  height: 378px;
  overflow: hidden;
  background-color: ${(props) => props.backcolor};
  border-radius: 15px;
  transition: 0.6s;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === "dark" ? "#191c27" : "#fff"};
  }
`;
export const CardHead = styled.h2`
  font-size: 20px;
  color: ${(props) => props.color};

  margin-left: 20px;

  text-align: start;
`;
export const CardText = styled.h2`
  margin: 0 20px;
  color: #777d92;
`;
export const LogoImg = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${Logo});
  ${CardContainer}:hover & {
    width: 55px;
    height: 55px;
    background-image: url(${Icon1});
    filter: grayscale(0);
  }
`;
export const GameLogoImg = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${Game});
  ${CardContainer}:hover & {
    width: 55px;
    height: 55px;
    background-image: url(${gameicon});
    filter: grayscale(0);
  }
`;
export const NftLogoImg = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${Nft});
  ${CardContainer}:hover & {
    width: 55px;
    height: 55px;
    background-image: url(${icon3});
    filter: grayscale(0);
  }
`;
export const DefiLogoImg = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${Defi});
  ${CardContainer}:hover & {
    width: 55px;
    height: 55px;
    background-image: url(${DefiLogos});
    filter: grayscale(0);
  }
`;
export const CardBody = styled.div`
  position: absolute;
  width: 279px;
  height: 30px;
  overflow: hidden;
  text-align: start;
  background: transparent;
  border-radius: 10px;
  transition: 0.6s;
  transition: all 0.3s linear;
  margin-top: 300px;
  position: absolute;
  background: transparent;
  font-size: 10px;
  line-height: 18px;
  color: #fff;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
  ${CardContainer}:hover & {
    margin-top: 100px;
    height: auto;
    padding-top: 10px;
    overflow: visible;
    background: transparent;
  }
`;
export const Btn = styled.a`
  color: black;

  background: #e7eaf3;

  padding: 15px;
  text-decoration: none;
  position: absolute;
  bottom: 25px;
  left: 25px;
  border-radius: 10px;
  border: none;
  opacity: -1;
  ${CardContainer}:hover & {
    opacity: 1;
  }
`;
// Heading
export const ProductWrapper = styled.div`
  margin: 5rem 0;
  width: 100%;
`;

export const Heading = styled.p`
  margin: 15px;
  width: 100%;
  font-size: 40px;
  line-height: 46.88px;
  color: ${(props) => props.theme.mode === "dark" && "#ffffff"};
  font-weight: bold;
  @media (max-width: 859px) {
    text-align: center;
    margin-left: 0.1rem;
    font-size: 22px;
  }
  @media (max-width: 500px) {
    text-align: center;

    font-size: 22px;
  }
`;
export const HeadSpan = styled.span`
  width: 100%;
  font-size: 40px;
  line-height: 47px;
  color: #ff0074;
  letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 100%, #6038F7 0%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: 859px) {
    font-size: 22px;
  }
`;
export const HeadSpan2 = styled.span`
  width: 100%;
  font-size: 40px;
  line-height: 47px;
  color: ${(props) => props.theme.mode === "dark" && "#ffffff"};
  letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: 859px) {
    display: none;
  }
`;
