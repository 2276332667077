import React, { useEffect, useState } from 'react';
import {
  Container,
  Data,
  DataRaisedCapital,
  Icon,
  LeftTextContainer,
  Number,
  NumberRaisedCapital,
  ProjectRaise,
  RaisedCapital,
  Text,
  TotalProject,
  Wrapperdetail,
} from './TotalProjects.styles';
import RaisedCapitalImage from './assets/RaisedCapitals.svg';
import TotalProjectImage from './assets/TotalProjects.svg';
import { getBackendUrl } from '../../api/constant';

function TotalProjects() {
  const [data, setData] = useState(null);
  const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch(`${apiUrl}projects/client/v1/stats?status=ended`)
          .then((response) => response.json())
          .then((res) => setData(res.data));
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <LeftTextContainer>
        <ProjectRaise>Total Projects & Capital Raised</ProjectRaise>By
        MetaLaunch
      </LeftTextContainer>

      <Wrapperdetail>
        <TotalProject>
          <Icon src={TotalProjectImage} alt='Frame' />
          <Text>
            <Number>{`${data?.totalProjects}+`}</Number>
            <Data>Total projects</Data>
          </Text>
        </TotalProject>

        <RaisedCapital>
          <Icon src={RaisedCapitalImage} alt='Frame3' />
          <Text>
            <NumberRaisedCapital>{`$ ${Math.floor(
              data?.totalFundraise
            ).toLocaleString('en-US')}`}</NumberRaisedCapital>
            <DataRaisedCapital>Raised Capital</DataRaisedCapital>
          </Text>
        </RaisedCapital>
      </Wrapperdetail>
    </Container>
  );
}

export default TotalProjects;
