import styled, { withTheme } from "styled-components";
import Carousel from "react-elastic-carousel";
import Slider from "react-slick";

export const SliderStyles = styled(Slider)`
  & .slick-arrow slick-prev {
    color: black;
  }
  & .slick-dots li button {
    width: 8px;
    margin-top: 20px;
    height: 8px;
    border-radius: 100%;
    background: ${(props) =>
      props.theme.mode === "dark"
        ? "rgba(255,255,255,0.13)"
        : props.theme.colors.grey["300"]};
  }
  & .slick-dots li.slick-active button {
    transition: 0.5s;
    width: 20px;
    height: 8px;
    border-radius: 10px;
    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
  }
  & .slick-dots li button:before {
    display: none;
  }
`;
export const Container = styled.div`
  width: 95%;
  height: auto;

  margin: 2rem auto 0 auto;
  @media (max-width: 782px) {
    margin: 0 1.5rem;
  }
`;

export const CarouselStyles = styled(Carousel)`
  & .rec-carousel-wrapper {
    width: 100%;
    @media screen and (max-width: 1296px) {
      width: 120%;
    }
  }
  & .rec-arrow {
    background-color: ${(props) =>
      props.theme.mode === "dark"
        ? props.theme.colors.black["400"]
        : "#F4F5F9"};
    min-width: 0px;
    width: 0px;
    box-shadow: 0 0;
    border: 1px solid
      ${(props) =>
        props.theme.mode === "dark"
          ? props.theme.colors.black["400"]
          : "#F4F5F9"};
    margin-top: 12rem;

    &:hover {
      background-color: ${(props) =>
        props.theme.mode === "dark"
          ? props.theme.colors.black["400"]
          : "#F4F5F9"};
      box-shadow: 0 0;
      color: ${(props) => (props.theme.mode === "dark" ? "white" : "black")};
    }
  }
  // & .rec-arrow-left {
  //   position: absolute;
  //   left: 28px;
  // }
  // & .rec-arrow-right {
  //   position: absolute;
  //   right: 28px;
  // }
  & .rec-arrow:disabled {
    visibility: hidden;
  }
  & .rec-dot {
    border: none;
    width: 8px;
    height: 8px;

    background: rgba(255, 255, 255, 0.13);
  }
  & .rec-dot_active {
    // width: 30px;
    // padding: 20px 0;

    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
    border-radius: 10px;
    transform: rotate(90deg);

    /* Inside auto layout */
  }
`;
export const ContainerHeading = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 6% 15% 1.4% 15%;
`;
export const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 145%;
  /* or 58px */

  letter-spacing: -1px;
  color: ${(props) => props.theme.mode === "dark" && "#ffffff"};
  letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  /* V2/Primary/500 */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
export const Heading2 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 145%;
  /* or 58px */

  letter-spacing: -1px;
  display: none;
  letter-spacing: -1px;
text-transform: capitalize;

/* grad */

background: linear-gradient(94.18deg, #FF0074 0%, #6038F7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  color: ${(props) => props.theme.mode === "dark" && "#ffffff"};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: block;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px;
  }
`;
export const IconsMainContainer = styled.div`
  position: relative;
`;
export const IconsSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Image = styled.img`
  

  position: absolute;
  right: -30px;

  bottom: 36px;
`;
export const ImageBanner = styled.div`
background: ${(props)=>props.theme.mode === "dark"?"#12141C":"#E7EAF3"};
box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 24px;
width:90%;
/* Inside auto layout */
margin-bottom: 20px;
flex: none;
order: 4;
flex-grow: 0;
`;
