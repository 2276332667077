import React from 'react';
import {
  Conatiner,
  DivImageAndPContainer,
  FooterAnchor,
  FooterDivContainer,
  FooterDivInsider,
  FooterDivTextContainer,
  FooterHiddenForSmallScreen,
  FooterMainDiv,
  FooterP3,
  ImageDiv,
  PTag,
  Conatiner2,
} from './FooterBottom.styles';
import FotterBottomLinks from './FotterBottomLinks/FotterBottomLinks';
import MetalaunchPicDark from './assets/MetalaunchPic.svg';
import MetalaunchPicLight from './assets/MetalaunchPicLight.svg';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import MetaLaunch from '../../assets/Metalaunchlogo.png';
import PrivacyGuidline from '../../assets/privacy-policy.pdf';
import termsGuidline from '../../assets/terms-of-use.pdf';
import SubscribeSection from '../SubscribeSection/SubscribeSection';

function FooterBottom() {
  const [currentTab, setCurrentTab] = useState('');
  const theme = useTheme();
  return (
    <Conatiner>
      <SubscribeSection />
      <FooterMainDiv>
        <DivImageAndPContainer>
          <ImageDiv>
            <img src={MetaLaunch} alt='' style={{ width: '100%' }} />
          </ImageDiv>
          <PTag>
            The web3 and metaverse Launchpad helps promising builders raise
            capital and provides the community with early access to outlier
            token deals.
          </PTag>
        </DivImageAndPContainer>
        <FooterDivTextContainer>
          <FooterDivContainer>
            <FooterP3>Metalaunch</FooterP3>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://app.metalaunch.io/'
                rel='noreferrer'
                onClick={() => setCurrentTab('IDO Launchpad')}
                active={currentTab === 'IDO Launchpad'}
              >
                IDO Launchpad
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://asvarium.com/'
                rel='noreferrer'
                onClick={() => setCurrentTab('INO Launchpad')}
                active={currentTab === 'INO Launchpad'}
              >
                NFT Launchpad
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://stake.metalaunch.io/'
                rel='noreferrer'
                onClick={() => setCurrentTab('Stake/Farm')}
                active={currentTab === 'Stake/Farm'}
              >
                Stake/Farm
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='http://army.metalaunch.io/'
                rel='noreferrer'
                onClick={() => setCurrentTab('Metalaunch Army')}
                active={currentTab === 'Metalaunch Army'}
              >
                Metalaunch Army
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://docs.google.com/forms/d/12Ue_5OJOu2f-xtytcMfjyl1tpeP01qZWqCJaEMjcO8I/viewform?edit_requested=true'
                rel='noreferrer'
                onClick={() => setCurrentTab('Apply for Incubation')}
                active={currentTab === 'Apply for Incubation'}
              >
                Apply for IDO
              </FooterAnchor>
            </FooterDivInsider>
          </FooterDivContainer>
          <FooterDivContainer>
            <FooterP3>Resources</FooterP3>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href={termsGuidline}
                without
                rel='noopener noreferrer'
                onClick={() => setCurrentTab('Tearms of service')}
                active={currentTab === 'Tearms of service'}
              >
                Terms Of Service
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href={PrivacyGuidline}
                without
                rel='noopener noreferrer'
                onClick={() => setCurrentTab('Privacy policy')}
                active={currentTab === 'Privacy policy'}
              >
                Privacy Policy
              </FooterAnchor>
            </FooterDivInsider>
            {/* <FooterDivInsider>
              <FooterAnchor
                target="_blank"
                href="#"
                rel="noreferrer"
                onClick={() => setCurrentTab("Help centre")}
                active={currentTab === "Help centre"}
              >
                Help centre
              </FooterAnchor>
            </FooterDivInsider> */}
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://drive.google.com/drive/u/0/folders/18PMe21BO1q2NOhq6Oj6qb35SS368SVyI'
                rel='noreferrer'
                onClick={() => setCurrentTab('Media Kit')}
                active={currentTab === 'Media Kit'}
              >
                Media Kit
              </FooterAnchor>
            </FooterDivInsider>
          </FooterDivContainer>
          <FooterDivContainer>
            <FooterP3>Connect</FooterP3>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='mailto:contact@metalaunch.io'
                rel='noreferrer'
                onClick={() => setCurrentTab('Email')}
                active={currentTab === 'Email'}
              >
                Email
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://asvalabs.notion.site/asvalabs/Asva-Labs-Careers-62d6bbac59854e3d8ad3bd97c2fceabc'
                rel='noreferrer'
                onClick={() => setCurrentTab('Careers')}
                active={currentTab === 'Careers'}
              >
                Careers
              </FooterAnchor>
            </FooterDivInsider>
          </FooterDivContainer>
          {/* <FooterHiddenForSmallScreen> */}
          <FooterDivContainer>
            <FooterP3>Community</FooterP3>
            {/* <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='mailto:contact@metalaunch.io'
                rel='noreferrer'
                onClick={() => setCurrentTab('Email')}
                active={currentTab === 'Email'}
              >
                Email
              </FooterAnchor>
            </FooterDivInsider> */}
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://twitter.com/Metalaunch_io'
                rel='noreferrer'
                onClick={() => setCurrentTab('Medium')}
                active={currentTab === 'Medium'}
              >
                Twitter
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://t.me/AsvaLabsOfficial'
                rel='noreferrer'
                onClick={() => setCurrentTab('Medium')}
                active={currentTab === 'Medium'}
              >
                Telegram
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://asvalabs.medium.com/'
                rel='noreferrer'
                onClick={() => setCurrentTab('Twitter')}
                active={currentTab === 'Twitter'}
              >
                Medium
              </FooterAnchor>
            </FooterDivInsider>
            <FooterDivInsider>
              <FooterAnchor
                target='_blank'
                href='https://www.linkedin.com/company/71280081/'
                rel='noreferrer'
                onClick={() => setCurrentTab('Linkdlin')}
                active={currentTab === 'Linkdlin'}
              >
                LinkedIn
              </FooterAnchor>
            </FooterDivInsider>
          </FooterDivContainer>
          {/* </FooterHiddenForSmallScreen> */}
        </FooterDivTextContainer>
      </FooterMainDiv>
      <Conatiner2>
        <FotterBottomLinks />
      </Conatiner2>
    </Conatiner>
  );
}

export default FooterBottom;
